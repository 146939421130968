import React from 'react';
import {
  Button,
  Col,
  Drawer,
  Form,
  Icon,
  Input,
  message,
  Modal,
  Row,
  Spin,
  Table,
  Tabs,
  Typography,
  Upload,
} from 'antd';
import 'antd/dist/antd.css';
import './meta.css';
import reqwest from 'reqwest';
import Dotdotdot from 'react-dotdotdot';

const { Search } = Input;
const { Text, Title } = Typography;
const path = require('path');
const image2base64 = require('image-to-base64');

var searchTimeoutId = false;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeCoverUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }

  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }

  return isJpgOrPng && isLt2M;
}

// function uploadImage(file, cb) {
// 	let reader = new FileReader();
// 	reader.readAsDataURL(file);

// 	reader.onload = function () {
// 		var b64 = reader.result;
// 		reqwest({
// 			url: process.env.REACT_APP_API + '/image/save',
// 			method: 'post',
// 			data: {
// 				image : b64
// 			},
// 			type: 'json',
// 		}).then((data) => {
// 			cb(data.src);
// 		});
// 	};

// 	reader.onerror = function (error) {
// 		console.log('Error: ', error);
// 	};

// 	return false;
// };

class App extends React.Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        title: 'Page Key WEB-SEO',
        dataIndex: 'page_key',
        render: (data) => (
          <div>
            <Dotdotdot clamp={1}>
              <Text style={{ wordWrap: 'break-word' }}>{data}</Text>
            </Dotdotdot>
          </div>
        ),
      },
      {
        title: 'Image',
        dataIndex: 'image',
        render: (data) => (
          <div>
            <img
              src={data}
              alt=""
              style={{ width: '100%', maxHeight: '50px', maxWidth: '50px' }}
            />
          </div>
        ),
      },
      {
        title: 'Title WEB-SEO',
        dataIndex: 'title',
        key: 'title',
        render: (data) => (
          <div>
            <Text>{data}</Text>
          </div>
        ),
      },
      {
        title: 'Description WEB-SEO',
        dataIndex: 'desc',
        key: 'desc',
        render: (data, row) => (
          <div>
            <Text>{data}</Text>
          </div>
        ),
      },
      {
        title: 'Manage',
        dataIndex: 'page_key',
        key: 'edit_page_key',
        width: 100,
        render: (key) => (
          <Button
            onClick={() => {
              this.showDrawer(key);
            }}
            className="icon-btn"
          >
            Edit
          </Button>
        ),
      },
    ];
  }

  showDrawer = async (key) => {
    this.props.pageLoading(true);
    var data = {};
    for (var i = 0; i < this.state.data.length; i++) {
      if (this.state.data[i].page_key === key) {
        data = this.state.data[i];
        break;
      }
    }

    image2base64(data.image) // you can also to use url
      .then((response) => {
        var b64 = 'data:image/';
        var ext = path.extname(data.image);
        if (ext === '.jpg' || ext === '.jpeg') {
          b64 += 'jpeg;base64,' + response;
        } else if (ext === '.png') {
          b64 += 'png;base64,' + response;
        }

        this.props.form.setFieldsValue({
          img: b64,
        });

        this.setState({
          imageUrl: b64,
        });
      })
      .catch((error) => {
        console.log(error); //Exepection error....
      });

    this.props.form.setFieldsValue({
      page_key: data.page_key,
      title: data.title,
      title_seo: data.title_seo,
      desc: data.desc,
      desc_seo: data.desc_seo,
    });

    this.setState({
      visible: true,
      detail: data,
      saveButton: true,
    });

    this.props.pageLoading(false);
  };

  state = {
    data: [],
    pagination: { pageSize: 10 },
    loading: false,
    visible: false,
    saveButton: false,
    imageUrl: false,
    searchValue: '',
    detail: {
      page_key: '',
      image: '',
      title: '',
      title_seo: '',
      desc: '',
      desc_seo: '',
      keyword_seo: '',
      updated_time: '',
    },
  };

  onSave = (e) => {
    this.setState({ loading: true });
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ saveButton: false });
        reqwest({
          url: process.env.REACT_APP_API + '/meta/save',
          method: 'post',
          data: values,
          type: 'json',
        }).then((data) => {
          if (data.success) {
            this.setState({ loading: false });
            this.setState({ visible: false });
            message.success('Save data complete.');
            this.fetch();
          } else {
            this.setState({ saveButton: true });
            this.setState({ loading: false });
            message.error('There was a problem updating! Please try again.');
          }
        });
      }
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  componentDidMount() {
    document.title = 'SEO Meta Data';

    this.fetch();
  }

  handleCoverChange = (info) => {
    if (info.file.status === 'uploading') {
      this.props.pageLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) => {
        this.props.form.setFieldsValue({
          img: imageUrl,
        });
        this.setState({
          imageUrl,
        });
        this.props.pageLoading(false);
      });
    }
  };

  getIndexInParent = (el) => {
    return Array.from(el.parentNode.children).indexOf(el);
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.fetch({
      size: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
  };

  fetch = (params = {}) => {
    this.props.pageLoading(true);
    reqwest({
      url: process.env.REACT_APP_API + '/meta',
      method: 'get',
      data: {
        size: this.state.pagination.pageSize,
        search: this.state.searchValue,
        ...params,
      },
      type: 'json',
    }).then((data) => {
      this.setState({
        data: data.results,
      });
      this.props.pageLoading(false);

      document.body.style.overflow = null;
    });
  };

  handleSearchChange(value) {
    this.setState({ searchValue: value });
    if (searchTimeoutId) {
      window.clearTimeout(searchTimeoutId);
    }
    searchTimeoutId = window.setTimeout(() => {
      this.fetch();
    }, 1500);
  }

  render() {
    const { saveButton, searchValue, detail, imageUrl } = this.state;
    const { getFieldDecorator } = this.props.form;

    const uploadCoverButton = (
      <div>
        <Icon type={this.state.loading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    return (
      <div id="meta-component">
        <Drawer
          id="meta-component-drawer"
          title="Meta Data"
          placement="right"
          closable={false}
          width={730}
          onClose={this.onClose}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 120 }}
        >
          <Spin tip="Loading..." spinning={this.state.loading} delay={100}>
            <Form onSubmit={this.onSave} layout={'vertical'}>
              <Form.Item label="Page Key WEB-SEO">
                {getFieldDecorator('page_key', {
                  initialValue: detail.page_key,
                  rules: [
                    { required: true, message: 'Please input Page Key!' },
                  ],
                })(
                  <Input
                    placeholder=""
                    readOnly={true}
                    style={{ backgroundColor: '#f5f5f5' }}
                  />,
                )}
              </Form.Item>
              <Form.Item label="Title WEB-SEO">
                {getFieldDecorator('title', {
                  initialValue: detail.title,
                  rules: [{ required: true, message: 'Please input Title!' }],
                })(<Input placeholder="" />)}
              </Form.Item>

              <Form.Item label="Description SEO">
                {getFieldDecorator('desc_seo', {
                  initialValue: detail.desc_seo,
                  rules: [
                    { required: true, message: 'Please input description!' },
                  ],
                })(<Input placeholder="" />)}
              </Form.Item>
              <Form.Item label="Keywords WEB-SEO">
                {getFieldDecorator('keyword_seo', {
                  initialValue: detail.keyword_seo,
                  rules: [{ required: true, message: 'Please input keyword!' }],
                })(<Input placeholder="" />)}
              </Form.Item>
              <Form.Item label="Title Facebook*">
                {getFieldDecorator('title_seo', {
                  initialValue: detail.title_seo,
                  rules: [{ required: true, message: 'Please input Title!' }],
                })(<Input placeholder="" />)}
              </Form.Item>
              <Form.Item label="Description Facebook">
                {getFieldDecorator('desc', {
                  initialValue: detail.desc,
                  rules: [
                    { required: true, message: 'Please input description!' },
                  ],
                })(<Input placeholder="" />)}
              </Form.Item>
              <Form.Item label="Image*">
                <Upload
                  name="image"
                  listType="picture-card"
                  className="photo-uploader"
                  showUploadList={false}
                  action={process.env.REACT_APP_API + '/image/test/upload'}
                  beforeUpload={beforeCoverUpload}
                  onChange={this.handleCoverChange}
                >
                  {imageUrl ? (
                    <img src={imageUrl} alt="Cover" style={{ width: '100%' }} />
                  ) : (
                    uploadCoverButton
                  )}
                </Upload>
                {getFieldDecorator('img')(<Input type="hidden" />)}
                <div className="text-center">
                  <Text>1200 x 900</Text>
                </div>
              </Form.Item>
              <div
                style={{
                  position: 'absolute',
                  right: 0,
                  bottom: 0,
                  width: '100%',
                  textAlign: 'left',
                  zIndex: 2,
                }}
              >
                <div
                  className="text-right"
                  style={{
                    paddingBottom: 10,
                    paddingRight: 16,
                    paddingLeft: 16,
                    paddingTop: 16,
                    background: 'rgba(255, 255, 255, 0.75)',
                  }}
                >
                  <Text className="">Please complete all required field*</Text>
                </div>
                <Row
                  style={{
                    width: '100%',
                    background: '#fff',
                    padding: '10px 16px',
                    borderTop: '1px solid #e9e9e9',
                  }}
                >
                  <Col span={12}>
                    <Button
                      onClick={this.onClose}
                      style={{ background: '#EDEDED' }}
                    >
                      Close
                    </Button>
                  </Col>
                  <Col span={12} className="text-right">
                    <Button
                      disabled={saveButton ? false : true}
                      htmlType="submit"
                      type="primary"
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          </Spin>
        </Drawer>
        <Row>
          <Col span={24}>
            <Title level={1}>Meta Data</Title>
          </Col>
        </Row>
        <div className="paper-panel">
          <Row>
            <Col span={12}>
              <Search
                placeholder="Search"
                onChange={({ target: { value } }) =>
                  this.handleSearchChange(value)
                }
                style={{ width: 350 }}
                value={searchValue}
              />
            </Col>
            <Col span={12} className="text-right"></Col>
          </Row>

          <Table
            rowKey="page_key"
            columns={this.columns}
            dataSource={this.state.data}
            pagination={this.state.pagination}
            loading={this.state.loading}
            onChange={this.handleTableChange}
          />
        </div>
      </div>
    );
  }
}

export default Form.create({ name: 'meta' })(App);
