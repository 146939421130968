import React from 'react';
import { Drawer, Icon, Row, Table, Typography } from 'antd';
import Search from 'antd/lib/input/Search';
import reqwest from 'reqwest';

import { DndProvider, DragSource, DropTarget } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';

const { Text } = Typography;
let searchTimeoutId = false;
let dragingIndex = -1;

class BodyRow extends React.Component {
  render() {
    const {
      isOver,
      connectDragSource,
      connectDropTarget,
      moveRow,
      ...restProps
    } = this.props;
    const style = { ...restProps.style, cursor: 'move' };

    let { className } = restProps;
    if (isOver) {
      if (restProps.index > dragingIndex) {
        className += ' drop-over-downward';
      }
      if (restProps.index < dragingIndex) {
        className += ' drop-over-upward';
      }
    }

    return connectDragSource(
      connectDropTarget(
        <tr {...restProps} className={className} style={style} />,
      ),
    );
  }
}

const rowSource = {
  beginDrag(props) {
    dragingIndex = props.index;
    return {
      index: props.index,
    };
  },
};

const rowTarget = {
  drop(props, monitor) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Time to actually perform the action
    props.moveRow(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  },
};

const DragableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
}))(
  DragSource('row', rowSource, (connect) => ({
    connectDragSource: connect.dragSource(),
  }))(BodyRow),
);

class ArticleHome extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      searchValue: null,
      loading: false,
    };

    this.columns = this.columns = [
      {
        title: 'Sort',
        dataIndex: 'sorting',
        key: 'sorting',
        width: 100,
        render: () => (
          <Icon
            type="menu"
            className="draggable"
            style={{
              fontSize: '13pt',
              fontWeight: 'bolder',
              cursor: 'pointer',
            }}
          />
        ),
      },
      {
        title: '',
        dataIndex: 'thumbnail_photo',
        key: 'image',
        render: (image) => <img width="50" src={image} alt="Thumbnail" />,
      },
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        render: (data) => (
          <div>
            <Text>{data[0]}</Text>
            <br />
            <Text>{data[1]}</Text>
          </div>
        ),
      },
      {
        title: 'Category',
        dataIndex: 'category_name',
        key: 'category',
        render: (data) => <Text>{data[1]}</Text>,
      },
      {
        title: 'Group',
        dataIndex: 'article_group_name',
        key: 'group',
        render: (data) => <Text>{data[1]}</Text>,
      },
      {
        title: 'Topic',
        dataIndex: 'topic_name',
        key: 'topic',
        render: (data) => <Text>{data[1]}</Text>,
      },
    ];
  }

  componentDidMount() {
    this.fetch();
  }

  moveRow = (dragIndex, hoverIndex) => {
    const { data } = this.state;
    const dragRow = data[dragIndex];

    this.setState(
      update(this.state, {
        data: {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        },
      }),
    );

    this.handleReorder(dragIndex, hoverIndex);
  };

  handleReorder = (dragIndex, draggedIndex) => {
    const data = this.state.data.map((item, index) => {
      return {
        key: item.key,
        sorting: index + 1,
      };
    });
    reqwest({
      url: process.env.REACT_APP_API + '/article/home/reorder',
      method: 'post',
      data: {
        data,
        type: 'default',
      },
      type: 'json',
    });
  };

  handleSetArticleHome = (openArticleHomeDrawer) => {
    const { handleSetArticleHome } = this.props;
    handleSetArticleHome(openArticleHomeDrawer);
  };

  handleSearchChange(value) {
    this.setState({ searchValue: value });
    if (searchTimeoutId) {
      window.clearTimeout(searchTimeoutId);
    }
    searchTimeoutId = window.setTimeout(() => {
      this.fetch();
    }, 1500);
  }

  onClose = () => {
    this.handleSetArticleHome(false);
  };

  fetch = (params = {}) => {
    this.props.pageLoading(true);
    reqwest({
      url: process.env.REACT_APP_API + '/article/home/selected',
      method: 'get',
      type: 'json',
    }).then((selected) => {
      reqwest({
        url: process.env.REACT_APP_API + '/article/home',
        method: 'get',
        data: {
          search: this.state.searchValue,
          category: this.state.searchCategory,
          topic: this.state.searchTopic,
          group: this.state.searchGroup,
          ...params,
        },
        type: 'json',
      }).then((data) => {
        let array = [...selected.results];
        data.results.forEach((item) => {
          const exist = array.find((f) => f.key === item.key);
          if (!exist) {
            array.push(item);
          }
        });
        this.setState({ data: array });
        this.props.pageLoading(false);
      });
    });
  };

  components = {
    body: {
      row: DragableBodyRow,
    },
  };

  render() {
    const { openArticleHomeDrawer } = this.props;
    return (
      <>
        <Drawer
          title="HOME PAGE HIGHLIGHT"
          placement="right"
          closable={false}
          width={930}
          onClose={this.onClose}
          visible={openArticleHomeDrawer}
          bodyStyle={{ paddingBottom: 120 }}
        >
          <Row type="flex" justify="end">
            <Search
              placeholder="Title"
              onChange={({ target: { value } }) =>
                this.handleSearchChange(value)
              }
              style={{ width: 240, marginRight: 8 }}
            />
          </Row>
          <DndProvider backend={HTML5Backend}>
            <Table
              columns={this.columns}
              dataSource={this.state.data}
              loading={this.state.loading}
              components={this.components}
              onRow={(record, index) => ({
                index,
                moveRow: this.moveRow,
              })}
              rowClassName={(record, index) => {
                return index < 3 ? 'highlight' : '';
              }}
              pagination={false}
            />
          </DndProvider>
        </Drawer>
      </>
    );
  }
}

export default ArticleHome;
