import React from 'react';
import {
  Button,
  Col,
  Drawer,
  Form,
  Icon,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Table,
  Typography,
} from 'antd';
import 'antd/dist/antd.css';
import './event_schedule.css';
import reqwest from 'reqwest';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import dragula from 'dragula';
import 'dragula/dist/dragula.css';

import { editorConfig } from './config.js';
import { wrapEditorTable } from './util/editor.util';
import TextEditor from './components/TextEditor';

const { Search } = Input;
const { Text, Title } = Typography;

var searchTimeoutId = false;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

var Size = ReactQuill.Quill.import('attributors/style/size');
Size.whitelist = ['13px', '14px', '16px', '18px', '20px', '32px', '72px'];
ReactQuill.Quill.register(Size, true);

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      pagination: { pageSize: 10 },
      loading: false,
      visible: false,
      deleteModalVisible: false,
      deleteLoading: false,
      imageUrl: false,
      imageBase64: '',
      searchValue: '',
      detail: {
        id: '',
        title_en: '',
        title_kh: '',
        description_en: '',
        description_kh: '',
        image: false,
        status: '1',
      },
    };

    this.columns = [
      {
        title: 'Sort',
        dataIndex: 'display_index',
        key: 'display_index',
        render: () => (
          <Icon
            type="menu"
            className="draggable"
            style={{
              fontSize: '13pt',
              fontWeight: 'bolder',
              cursor: 'pointer',
            }}
          />
        ),
      },
      {
        title: 'Message',
        dataIndex: 'title',
        key: 'message',
        render: (data) => (
          <div>
            <Text>{data[0]}</Text>
            <br />
            <Text>{data[1]}</Text>
          </div>
        ),
      },
      {
        title: 'Published',
        dataIndex: 'status',
        key: 'active',
        width: 100,
        align: 'center',
        render: (status) =>
          status === '1' ? (
            <i className="fa fa-check" style={{ fontSize: 15 }}></i>
          ) : (
            <i className="fa fa-times" style={{ fontSize: 15 }}></i>
          ),
      },
      {
        title: '',
        dataIndex: 'key',
        key: 'key',
        width: 50,
        render: (key) => (
          <Button
            onClick={() => {
              this.showDrawer(key);
            }}
            className="icon-btn"
          >
            <Icon type="setting" />
          </Button>
        ),
      },
    ];
  }

  componentDidMount() {
    document.title = 'Event Schedule';

    this.fetch();

    const container = document.querySelector('.ant-table-tbody');
    const drake = dragula([container], {
      moves: (el) => {
        this.start = this.getIndexInParent(el);
        return true;
      },
    });

    drake.on('drop', (el) => {
      this.end = this.getIndexInParent(el);
      this.handleReorder(this.start, this.end);
    });
  }

  showDrawer = async (key) => {
    var data = {};
    if (key === -1) {
      data = {
        title_en: '',
        title_kh: '',
        description_en: '',
        description_kh: '',
        status: '1',
      };
      this.setState({
        imageUrl: null,
      });
    } else {
      for (var i = 0; i < this.state.data.length; i++) {
        if (this.state.data[i].key === key) {
          data = this.state.data[i];
          break;
        }
      }
    }

    this.props.form.setFieldsValue({
      id: data.key,
      title_en: data.title_en,
      title_kh: data.title_kh,
      description_en: data.description_en,
      description_kh: data.description_kh,
      status: data.status,
    });

    this.setState({
      visible: true,
      detail: data,
      imageUrl: false,
    });
  };

  modules = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        ['link', 'image'],

        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        // [{ 'direction': 'rtl' }],                         // text direction

        // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ size: Size.whitelist }],

        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        // [{ 'font': Font.whitelist }],
        [{ align: [] }],

        ['clean'], // remove formatting button
      ],
      handlers: {
        image: this.imageHandler,
      },
    },
  };

  onSave = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // set value from editor
        values.description_en = wrapEditorTable(
          this.state.detail.description_en,
        );
        values.description_kh = wrapEditorTable(
          this.state.detail.description_kh,
        );
        reqwest({
          url: process.env.REACT_APP_API + '/event_scheduler/save',
          method: 'post',
          data: values,
          type: 'json',
        }).then((data) => {
          if (data.success) {
            message.success('Save data complete.');
            this.setState({ visible: false });
            this.fetch();
          } else {
            this.setState({ saveButton: true });
            message.error('There was a problem updating! Please try again.');
          }
        });
      }
    });
  };

  handleChange = (info) => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) => {
        this.props.form.setFieldsValue({
          image: imageUrl,
        });
        this.setState({
          imageUrl,
          loading: false,
        });
      });
    }
  };

  showDeleteModal = () => {
    this.setState({
      deleteModalVisible: true,
    });
  };

  handleDeleteModalOk = () => {
    var detail = this.state.detail;
    this.setState({ deleteLoading: true });

    reqwest({
      url: process.env.REACT_APP_API + '/event_scheduler/delete',
      method: 'post',
      data: {
        id: detail.key,
      },
      type: 'json',
    }).then((data) => {
      this.setState({
        deleteLoading: false,
        deleteModalVisible: false,
        visible: false,
      });
      message.success('Remove data complete.');
      this.fetch();
    });
  };

  handleDeleteModalCancel = () => {
    this.setState({ deleteModalVisible: false });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  getIndexInParent = (el) => {
    return Array.from(el.parentNode.children).indexOf(el);
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.fetch({
      size: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
  };

  handleReorder = (dragIndex, draggedIndex) => {
    const data = [...this.state.data];
    const item = data.splice(dragIndex, 1)[0];
    data.splice(draggedIndex, 0, item);
    this.setState({
      data,
    });

    var postBody = [];
    for (var i = 0; i < data.length; i++) {
      postBody.push({
        key: data[i].key,
        display_order: i + 1,
      });
    }

    console.log(postBody);

    reqwest({
      url: process.env.REACT_APP_API + '/event_scheduler/reorder',
      method: 'post',
      data: {
        v: postBody,
      },
      type: 'json',
    }).then(() => {});
  };

  fetch = (params = {}) => {
    this.setState({ loading: true });
    reqwest({
      url: process.env.REACT_APP_API + '/event_scheduler',
      method: 'get',
      data: {
        size: this.state.pagination.pageSize,
        search: this.state.searchValue,
        ...params,
      },
      type: 'json',
    }).then((data) => {
      this.setState({
        loading: false,
        data: data.results,
      });
      document.body.style.overflow = null;
    });
  };

  handleSearchChange(value) {
    this.setState({ searchValue: value });
    if (searchTimeoutId) {
      window.clearTimeout(searchTimeoutId);
    }
    searchTimeoutId = window.setTimeout(() => {
      this.fetch();
    }, 1500);
  }

  handleChangeEditor = (key, value) => {
    this.setState({
      detail: {
        ...this.state.detail,
        [key]: value,
      },
    });
  };

  render() {
    const { deleteModalVisible, deleteLoading, detail } = this.state;
    const { getFieldDecorator } = this.props.form;

    return (
      <div id="event-schedule-component">
        <Row>
          <Col span={24}>
            <Title level={1}>Schedule</Title>
          </Col>
        </Row>
        <div className="paper-panel">
          <Row>
            <Col span={12}>
              <Search
                placeholder="Message / Customer"
                onChange={({ target: { value } }) =>
                  this.handleSearchChange(value)
                }
                style={{ width: 350 }}
              />
            </Col>
            <Col span={12} className="text-right">
              <Button
                type="primary"
                style={{ minWidth: 140 }}
                onClick={() => this.showDrawer(-1)}
              >
                <Icon type="plus" />
                Create
              </Button>
            </Col>
          </Row>
          <Table
            columns={this.columns}
            dataSource={this.state.data}
            pagination={this.state.pagination}
            loading={this.state.loading}
            onChange={this.handleTableChange}
          />
        </div>
        <Drawer
          title="EDIT"
          placement="right"
          closable={false}
          width={730}
          onClose={this.onClose}
          destroyOnClose
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <Form onSubmit={this.onSave} layout={'vertical'}>
            <Form.Item label="" style={{ display: 'none' }}>
              {getFieldDecorator('id', {
                initialValue: detail.id,
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Title EN *">
              {getFieldDecorator('title_en', {
                validateTrigger: ['onChange', 'onBlur'],
                initialValue: detail.title_en,
                rules: [{ required: true, message: 'Please input title!' }],
              })(<Input placeholder="" />)}
            </Form.Item>
            <Form.Item label="Title KH *">
              {getFieldDecorator('title_kh', {
                validateTrigger: ['onChange', 'onBlur'],
                initialValue: detail.title_kh,
                rules: [{ required: true, message: 'Please input title!' }],
              })(<Input placeholder="" />)}
            </Form.Item>
            <Form.Item label="Detail EN *">
              {/* {getFieldDecorator('description_en', {
								initialValue: detail.description_en,
								rules: [{ required: true, message: 'Please input detail!' }],
							})(
								<ReactQuill id="event-schedule-desc" modules={this.modules} />
							)} */}
              <TextEditor
                value={this.state.detail.description_en}
                ref={this.setRef}
                config={editorConfig}
                onChange={(e) => this.handleChangeEditor('description_en', e)}
              />
            </Form.Item>
            <Form.Item label="Detail KH *">
              {/* {getFieldDecorator('description_kh', {
								initialValue: detail.description_kh,
								rules: [{ required: true, message: 'Please input detail!' }],
							})(
								<ReactQuill id="event-schedule-desc" modules={this.modules} />
							)} */}
              <TextEditor
                value={this.state.detail.description_kh}
                ref={this.setRef}
                config={editorConfig}
                onChange={(e) => this.handleChangeEditor('description_kh', e)}
              />
            </Form.Item>
            <Form.Item label="Publish Status*">
              {getFieldDecorator('status', {
                initialValue: detail.status,
                rules: [{ required: true, message: 'Please choose status!' }],
              })(
                <Radio.Group>
                  <Radio.Button value="1">
                    <i className="fa fa-check" style={{ marginRight: 5 }}></i>
                    Active
                  </Radio.Button>
                  <Radio.Button value="0">
                    <i className="fa fa-close" style={{ marginRight: 5 }}></i>
                    Inactive
                  </Radio.Button>
                </Radio.Group>,
              )}
            </Form.Item>

            <div
              style={{
                position: 'absolute',
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e8e8e8',
                padding: '10px 16px',
                left: 0,
                background: '#fff',
                borderRadius: '0 0 4px 4px',
                zIndex: 2,
              }}
            >
              <Row>
                <Col span={12}>
                  <Button onClick={this.onClose} className="close-btn">
                    Close
                  </Button>
                </Col>
                <Col span={12} className="text-right">
                  <Button
                    style={{
                      marginRight: 8,
                    }}
                    className="secondary-action-btn"
                    onClick={this.showDeleteModal}
                  >
                    <i className="fa fa-close" style={{ marginRight: 5 }}></i>
                    Delete
                  </Button>
                  <Button
                    onClick={this.onClose}
                    htmlType="submit"
                    type="primary"
                    className="primary-action-btn"
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </Drawer>

        <Modal
          visible={deleteModalVisible}
          width={400}
          closable={false}
          footer={[
            <Button
              key="submit"
              loading={deleteLoading}
              onClick={this.handleDeleteModalOk}
            >
              Yes, Delete
            </Button>,
            <Button
              key="back"
              type="primary"
              onClick={this.handleDeleteModalCancel}
              style={{ minWidth: 120 }}
            >
              No
            </Button>,
          ]}
        >
          <div class="text-center">
            <p>Do you want to delete this item?</p>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Form.create({ name: 'event_scheduler_form' })(App);
