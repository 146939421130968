export function cleanupURL(url) {
  if (url) {
    return encodeURIComponent(
      url.toLowerCase().replace(/[^a-zA-Z\s\d]+/g, ' '),
    ).replace(/%20/g, '-');
  }

  return '';
}

export const getTopicName = (name) => {
  if (cleanupURL(name).includes('week')) {
    return 'week';
  } else if (cleanupURL(name).includes('month')) {
    return 'month';
  } else if (cleanupURL(name).includes('year')) {
    return 'year';
  } else {
    return cleanupURL(name);
  }
};

export const isTimelyTopic = (name) => {
  if (cleanupURL(name).includes('week')) {
    return true;
  } else if (cleanupURL(name).includes('month')) {
    return true;
  } else if (cleanupURL(name).includes('year')) {
    return true;
  } else {
    return false;
  }
};

export const getArticleURL = (data) => {
  if (data.title_en !== '') {
    if (isTimelyTopic(data.topic_name_en)) {
      return `${
        '/' +
        cleanupURL(data.category_name_en) +
        '/' +
        getTopicName(
          data.topic_name_en_new === null
            ? data.topic_name_en
            : data.topic_name_en_new,
        ) +
        '/' +
        (data.url_name !== '' && data.url_name !== null
          ? cleanupURL(data.url_name)
          : cleanupURL(data.title_en))
      }`;
    } else {
      // return `${'/'+cleanupURL(data.category_name_en)+'/'+getTopicName(data.topic_name_en)+'/article_id/?article_id='+cleanupURL(data.title_en)}`
      return `${
        '/' +
        cleanupURL(data.category_name_en) +
        '/' +
        getTopicName(
          data.topic_name_en_new === null
            ? data.topic_name_en
            : data.topic_name_en_new,
        ) +
        '/' +
        (data.url_name !== '' && data.url_name !== null
          ? cleanupURL(data.url_name)
          : cleanupURL(data.title_en))
      }`;
    }
  } else {
    return '';
  }
};

export const getProductURL = (data) => {
  if (data.name !== '') {
    return `${
      '/product/' +
      data.type_name +
      '/' +
      (data.url_name !== '' && data.url_name !== null
        ? cleanupURL(data.url_name)
        : data.id)
    }`;
  } else {
    return '';
  }
};

export const isEmpty = (obj) => {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
};
