import React from 'react';
import {
  Button,
  DatePicker,
  Drawer,
  Table,
  Col,
  Form,
  Select,
  Row,
  Typography,
} from 'antd';
import './questionnaire.css';
import moment from 'moment';

const { RangePicker } = DatePicker;
const { Title } = Typography;
const { Option } = Select;

class App extends React.Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        title: 'Submitted',
        dataIndex: 'created_time',
        key: 'created_time',
        render: (created_time) => moment(created_time).format('DD/MM/YY'),
      },
      {
        title: 'Mom Name',
        dataIndex: 'mom_name',
        key: 'mom_name',
      },
      {
        title: 'Child Gender',
        dataIndex: 'child_gender',
        key: 'child_gender',
        render: (gender) => (gender === 'M' ? 'Boy' : 'Girl'),
      },
      {
        title: 'Child DOB',
        dataIndex: 'child_data_of_birth',
        key: 'child_data_of_birth',
        render: (dob) => moment(dob).format('DD/MM/YY'),
      },
      {
        title: 'Phone Number',
        dataIndex: 'phone_number',
        key: 'phone_number',
      },
      {
        title: 'Country',
        dataIndex: 'country',
        key: 'country',
      },
      {
        title: 'Feeding Type',
        dataIndex: 'feeding_type',
        key: 'feeding_type',
      },
      {
        title: 'Formula using',
        dataIndex: 'formula_using',
        key: 'formula_using',
      },
      {
        title: 'Result',
        dataIndex: 'result',
        key: 'result',
        render: (result) => (result < 100 ? 'High Risk' : 'Low Risk'),
      },
      {
        title: 'Details',
        dataIndex: 'id',
        key: 'details',
        render: (id) => (
          <a onClick={() => this.onDrawer(true, id)}>View</a>
        ),
      },
    ];

    this.state = {
      data: [],
      drawer: {},
      filter: {
        filter_date: null,
        age_group: null,
        result: null,
      },
      placeholder: ['Start Date', 'End Date'],
      pagination: {},
      loading: false,
      visible: false,
    };
  }

  componentDidMount() {
    document.title = 'Questionnaire';
    this.fetch();
  }

  fetch = async () => {
    this.setState({ loading: true });
    try {
      const response = await fetch(`${process.env.REACT_APP_API}/iron_question`);
      const data = await response.json();
      this.setState({
        data: data.results,
        loading: false,
      });
    } catch (error) {
      this.setState({ loading: false });
      console.error('Error fetching data:', error);
    }
  };

  onDrawer = async (bl, id = null) => {
    this.setState({ visible: bl });

    if (id === null) {
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API}/iron_question/view?id=${id}`);
      const data = await response.json();
      this.setState({
        drawer: data,
        loading: false,
      });
    } catch (error) {
      this.setState({ loading: false });
      console.error('Error fetching data:', error);
    }
  };

  filterData = async () => {
    const { filter } = this.state;
    const { filter_date, result, age_group } = filter;

    const body = {
      age_group_id: age_group || null,
      results: result || null,
      range_date: filter_date || null,
    };

    this.setState({ loading: true });
    try {
      const response = await fetch(`${process.env.REACT_APP_API}/iron_question`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });
      const data = await response.json();
      this.setState({
        data: data.results,
        loading: false,
      });
    } catch (error) {
      this.setState({ loading: false });
      console.error('Error fetching data:', error);
    }
  };

  handleFormChange = (changedValues) => {
    const { filter } = this.state;
    this.setState(
      {
        filter: {
          ...filter,
          ...changedValues,
        },
      },
      () => {
        this.filterData();
      },
    );

    if (changedValues.filter_date) {
      const [start, end] = changedValues.filter_date;
      this.setState({
        placeholder: start && end ? [start.format('DD/MM/YYYY'), end.format('DD/MM/YYYY')] : ['Start Date', 'End Date'],
      });
    }
  };

  exportExcel = async () => {
    const { filter } = this.state;
    const body = {
      age_group_id: filter.age_group || null,
      results: filter.result || null,
      range_date: filter.filter_date || null,
    };

    this.setState({ loading: true });
    try {
      const response = await fetch(`${process.env.REACT_APP_API}/iron_question/export`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'results.xlsx';
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      } else {
        console.error('Failed to download file');
      }

      this.setState({ loading: false });
    } catch (error) {
      this.setState({ loading: false });
      console.error('Error fetching data:', error);
    }
  };

  resetFilter = () => {
    this.props.form.resetFields();
    this.setState({
      filter: {
        filter_date: null,
        age_group: null,
        result: null,
      },
      placeholder: ['Start Date', 'End Date'],
    }, () => {
      this.fetch();
    });
  };

  render() {
    const { visible, drawer, filter, placeholder, data, pagination, loading } = this.state;
    const { getFieldDecorator } = this.props.form;

    return (
      <div id="questionnaire-component">
        <Drawer
          id="question-component-drawer"
          title="Questionnaire"
          placement="right"
          closable={true}
          width={730}
          onClose={() => this.onDrawer(false)}
          destroyOnClose
          visible={visible}
        >
          <Row>
            <Col span={24}>
              <Title level={3}>{drawer.question_group}</Title>
            </Col>
          </Row>
          {drawer.questions &&
            drawer.questions.map((qa, index) => (
              <div key={qa.id}>
                <Row>
                  <Col span={1}>
                    <strong>{index + 1}</strong>
                  </Col>
                  <Col span={3}>
                    <p>
                      <strong>question : </strong>
                    </p>
                  </Col>
                  <Col span={20}>{qa.question_km}</Col>
                </Row>
                <Row>
                  <Col span={1}></Col>
                  <Col span={3}>
                    <p>
                      <strong>answer : </strong>
                    </p>
                  </Col>
                  <Col span={20}>{qa.selectedAnswer.answer_km}</Col>
                </Row>
                <div
                  style={{
                    border: 'none',
                    borderTop: '1px solid #EDEDED',
                    marginBottom: '10px',
                  }}
                />
              </div>
            ))}
        </Drawer>

        <Row>
          <Col span={24}>
            <Title level={1}>Questionnaire</Title>
          </Col>
        </Row>

        <div className="questionnaire-container">
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Form.Item>
                {getFieldDecorator('filter_date', {
                  initialValue: filter.filter_date,
                })(
                  <RangePicker
                    format={'DD/MM/YYYY'}
                    placeholder={placeholder}
                    style={{ width: '100%' }}
                    onChange={(dates) => this.handleFormChange({ filter_date: dates })}
                  />,
                )}
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item>
                {getFieldDecorator('result', {
                  initialValue: filter.result,
                })(
                  <Select
                    placeholder="Result"
                    style={{ width: '100%' }}
                    onChange={(value) => this.handleFormChange({ result: value })}
                  >
                    <Option value="low">Low</Option>
                    <Option value="high">High</Option>
                  </Select>,
                )}
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item>
                {getFieldDecorator('age_group', {
                  initialValue: filter.age_group,
                })(
                  <Select
                    placeholder="Age Group"
                    style={{ width: '100%' }}
                    onChange={(value) => this.handleFormChange({ age_group: value })}
                  >
                    <Option value="1">6 months - 1 year old</Option>
                    <Option value="2">1 – 3 years old</Option>
                    <Option value="3">4 - 6 years old</Option>
                  </Select>,
                )}
              </Form.Item>
            </Col>
            <Col span={3}>
              <Button onClick={this.resetFilter}>Reset</Button>
            </Col>
            <Col span={3}>
              <Button type='primary' style={{width:'100%'}} onClick={this.exportExcel}>Export</Button>
            </Col>
          </Row>
          <Table
            columns={this.columns}
            dataSource={data}
            pagination={pagination}
            loading={loading}
            onChange={this.handleTableChange}
          />
        </div>
      </div>
    );
  }
}

export default Form.create({ name: 'questionnaire' })(App);