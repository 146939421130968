import React from 'react';
import {
  Button,
  Col,
  Drawer,
  Form,
  Icon,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Table,
  Typography,
} from 'antd';
import 'antd/dist/antd.css';
import './system_user.css';
import reqwest from 'reqwest';

import cookie from 'js-cookie';
const { Search } = Input;
const { Title } = Typography;

var searchTimeoutId = false;

class App extends React.Component {
  state = {
    data: [],
    detail: {
      username: '',
      password: '',
      confirm: '',
      email: '',
      role: '',
      status: '1',
    },
    key: false,
    loading: false,
    visible: false,
    imageUrl: false,
    imageBase64: '',
    deleteModalVisible: false,
    deleteLoading: false,
    pagination: { pageSize: 10 },
    searchValue: '',
    role: [],
    message: '',
    errorVisible: false,
  };

  constructor(props) {
    super(props);

    this.columns = [
      {
        title: 'Title',
        dataIndex: 'username',
        key: 'username',
      },
      {
        title: 'Create Date',
        dataIndex: 'created_time',
        key: 'created_time',
      },
      {
        title: 'Active',
        dataIndex: 'status',
        key: 'active',
        width: 100,
        align: 'center',
        render: (status) =>
          status === 1 ? (
            <i className="fa fa-check" style={{ fontSize: 15 }}></i>
          ) : (
            <i className="fa fa-times" style={{ fontSize: 15 }}></i>
          ),
      },
      {
        title: '',
        dataIndex: 'key',
        key: 'key',
        width: 50,
        render: (key) => (
          <Button
            onClick={() => {
              this.showDrawer(key);
            }}
            className="icon-btn"
          >
            <Icon type="setting" />
          </Button>
        ),
      },
    ];
  }

  componentDidMount() {
    document.title = 'System User';

    this.fetch();
  }

  showDrawer = async (key) => {
    var data = {};
    if (key === -1) {
      data = {
        username: '',
        password: '',
        confirm: '',
        role: '',
        email: '',
        status: '1',
      };
      this.setState({
        imageUrl: null,
      });
    } else {
      for (var i = 0; i < this.state.data.length; i++) {
        if (this.state.data[i].key === key) {
          data = this.state.data[i];
          data.password = '';
          data.confirm = '';
          break;
        }
      }
    }

    this.setState({
      visible: true,
      detail: data,
    });

    this.props.form.setFieldsValue({
      id: data.key,
      username: data.username,
      password: data.password,
      email: data.email,
      confirm: data.confirm,
      role: data.role,
      status: '' + data.status,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  onSave = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        reqwest({
          url: process.env.REACT_APP_API + '/system_user/save',
          method: 'post',
          data: values,
          type: 'json',
        }).then((data) => {
          if (data.success) {
            message.success('Save data complete.');
            this.setState({ visible: false });
            this.fetch();
          } else {
            message.error('There was a problem updating! Please try again.');
            this.showErrorModal(data.message);
          }
        });
      }
    });
  };

  handleSearchChange(value) {
    this.setState({ searchValue: value });
    if (searchTimeoutId) {
      window.clearTimeout(searchTimeoutId);
    }
    searchTimeoutId = window.setTimeout(() => {
      this.fetch();
    }, 1500);
  }

  fetch = (params = {}) => {
    console.log('params:', params);

    this.props.pageLoading(true);

    reqwest({
      url: process.env.REACT_APP_API + '/system_user',
      method: 'get',
      data: {
        search: this.state.searchValue,
        token: cookie.get('token'),
        ...params,
      },
      type: 'json',
    }).then((data) => {
      this.setState({
        data: data.results,
      });

      this.props.pageLoading(false);
      document.body.style.overflow = null;
    });

    reqwest({
      url: process.env.REACT_APP_API + '/system_user/init',
      method: 'get',
      data: {},
      type: 'json',
    }).then((data) => {
      this.setState({
        role: data.results.role,
      });
    });
  };

  showDeleteModal = () => {
    this.setState({
      deleteModalVisible: true,
    });
  };

  handleDeleteModalCancel = () => {
    this.setState({ deleteModalVisible: false });
  };

  showErrorModal = (message) => {
    this.setState({
      errorVisible: true,
      message: message,
    });
  };

  closeErrorModal = () => {
    this.setState({
      errorVisible: false,
    });
  };

  handleDeleteModalOk = () => {
    var detail = this.state.detail;
    this.setState({ deleteLoading: true });

    reqwest({
      url: process.env.REACT_APP_API + '/system_user/delete',
      method: 'post',
      data: {
        id: detail.key,
      },
      type: 'json',
    }).then(() => {
      this.setState({
        deleteLoading: false,
        deleteModalVisible: false,
        visible: false,
      });
      message.success('Remove data complete.');
      this.fetch();
    });
  };

  render() {
    const { deleteModalVisible, deleteLoading, message, detail, role } =
      this.state;
    const { getFieldDecorator } = this.props.form;

    return (
      <div>
        <Drawer
          id="promotion-banner-component-drawer"
          title="SYSTEM USER"
          placement="right"
          closable={false}
          width={460}
          onClose={this.onClose}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <Form onSubmit={this.onSave} layout={'vertical'}>
            <Form.Item label="" style={{ display: 'none' }}>
              {getFieldDecorator('id', {
                initialValue: '',
              })(<Input />)}
            </Form.Item>
            <Row gutter={[16, 0]}>
              <Col span={12}>
                <Form.Item label="Username">
                  {getFieldDecorator('username', {
                    initialValue: detail.username,
                    rules: [
                      {
                        required: true,
                        message: 'Please input your username!',
                      },
                    ],
                  })(<Input placeholder="" />)}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Email">
                  {getFieldDecorator('email', {
                    initialValue: detail.email,
                    rules: [
                      { required: true, message: 'Please input your email!' },
                    ],
                  })(<Input placeholder="" autoComplete="new-password" />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 0]}>
              <Col span={12}>
                <Form.Item label="Password">
                  {getFieldDecorator('password', {
                    initialValue: detail.password,
                  })(
                    <Input.Password
                      placeholder=""
                      autoComplete="new-password"
                    />,
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Re-Password">
                  {getFieldDecorator('confirm', {
                    initialValue: detail.confirm,
                  })(
                    <Input.Password
                      placeholder=""
                      autoComplete="new-password"
                    />,
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label="Publish Status*">
              {getFieldDecorator('status', {
                initialValue: '' + detail.status,
                rules: [{ required: true, message: 'Please choose status!' }],
              })(
                <Radio.Group>
                  <Radio.Button value="1">
                    <i className="fa fa-check" style={{ marginRight: 5 }}></i>
                    Active
                  </Radio.Button>
                  <Radio.Button value="0">
                    <i className="fa fa-close" style={{ marginRight: 5 }}></i>
                    Inactive
                  </Radio.Button>
                </Radio.Group>,
              )}
            </Form.Item>
            <Form.Item label="Role*">
              {getFieldDecorator('role', {
                initialValue: '',
                rules: [{ required: true, message: 'Please choose role!' }],
              })(
                <Select>
                  <Select.Option key="" value="">
                    Please select
                  </Select.Option>
                  {role.map((value) => (
                    <Select.Option key={value.id} value={value.id}>
                      {value.name}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </Form.Item>

            <div
              style={{
                position: 'absolute',
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e8e8e8',
                padding: '10px 16px',
                left: 0,
                background: '#fff',
                borderRadius: '0 0 4px 4px',
                zIndex: 2,
              }}
            >
              <Row>
                <Col span={12}>
                  <Button
                    onClick={this.onClose}
                    style={{ background: '#EDEDED' }}
                  >
                    Close
                  </Button>
                </Col>
                <Col span={12} className="text-right">
                  <Button
                    style={{
                      marginRight: 8,
                    }}
                    onClick={this.showDeleteModal}
                  >
                    <i className="fa fa-close" style={{ marginRight: 5 }}></i>
                    Delete
                  </Button>
                  <Button htmlType="submit" type="primary">
                    Save
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </Drawer>
        <Row>
          <Col span={24}>
            <Title level={1}>System User</Title>
          </Col>
        </Row>
        <div className="paper-panel">
          <Row>
            <Col span={12}>
              <Search
                placeholder="Username"
                onChange={({ target: { value } }) =>
                  this.handleSearchChange(value)
                }
                style={{ width: 350 }}
              />
            </Col>
            <Col span={12} className="text-right">
              <Button
                type="primary"
                style={{ minWidth: 140 }}
                onClick={() => this.showDrawer(-1)}
              >
                <Icon type="plus" />
                Create
              </Button>
            </Col>
          </Row>
          <Table
            columns={this.columns}
            dataSource={this.state.data}
            pagination={this.state.pagination}
            loading={this.state.loading}
            onChange={this.handleTableChange}
          />
        </div>

        <Modal
          visible={deleteModalVisible}
          closable={false}
          width={400}
          footer={[
            <Button
              key="submit"
              loading={deleteLoading}
              onClick={this.handleDeleteModalOk}
            >
              Yes, Delete
            </Button>,
            <Button
              key="back"
              type="primary"
              onClick={this.handleDeleteModalCancel}
              style={{ minWidth: 120 }}
            >
              No
            </Button>,
          ]}
        >
          <div className="text-center">
            <p>Do you want to delete this item?</p>
          </div>
        </Modal>

        <Modal
          title="Error"
          visible={this.state.errorVisible}
          onOk={this.closeErrorModal}
          onCancel={this.closeErrorModal}
          footer={[
            <Button key="ok" type="primary" onClick={this.closeErrorModal}>
              OK
            </Button>,
          ]}
        >
          <p>{message}</p>
        </Modal>
      </div>
    );
  }
}

export default Form.create({ name: 'system_user_form' })(App);
