import React from 'react';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import 'antd/dist/antd.css';
import './sampling.css';
import reqwest from 'reqwest';
import Dotdotdot from 'react-dotdotdot';
import moment from 'moment';

const { Search } = Input;
const { Text, Title } = Typography;
const { RangePicker } = DatePicker;

var searchTimeoutId = false;

class App extends React.Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        title: 'Requested',
        dataIndex: 'created_time',
        key: 'message',
        render: (data) => (
          <div>
            <Dotdotdot clamp={1}>
              <Text style={{ wordWrap: 'break-word' }}>
                {moment(data).format('DD/MM/YYYY')}
              </Text>
            </Dotdotdot>
          </div>
        ),
      },
      {
        title: 'Full name',
        dataIndex: 'fullname',
        key: 'fullname',
        render: (data) => (
          <div>
            <Dotdotdot clamp={1}>
              <Text style={{ wordWrap: 'break-word' }}>{data}</Text>
            </Dotdotdot>
          </div>
        ),
      },
      {
        title: 'Phone number',
        dataIndex: 'phone',
        key: 'phone',
        render: (data, row) => (
          <div>
            <Text>{data}</Text>
          </div>
        ),
      },
      {
        title: 'Are you pregnant?',
        dataIndex: 'pregnant',
        key: 'active',
        align: 'center',
        render: (pregnant) => (
          <div>
            <Text>{pregnant}</Text>
          </div>
        ),
      },
      {
        title: 'Pregnant Week',
        dataIndex: 'pregnant_week_str',
        key: 'pregnant_week_str',
        render: (data, row) => (
          <div>
            <Tooltip title={row.pregnant_date}>
              <Text>{data}</Text>
            </Tooltip>
          </div>
        ),
      },
      {
        title: "Child's Age",
        dataIndex: 'used_date',
        key: 'used_date',
        render: function (data, row) {
          var txt = '';
          let empty = false;

          var today = moment();
          var diff = today.diff(moment(data, 'YYYY-MM-DD'));
          var duration = moment.duration(diff);

          if (duration.years()) {
            txt += duration.years() + ' Years ';
          }

          txt += duration.months() + ' Months ';

          if (!duration.years() && !duration.months()) {
            empty = true;
          }

          if (empty) {
            return <></>;
          }

          return (
            <div>
              <Tooltip title={data}>
                <Text>{txt}</Text>
              </Tooltip>
            </div>
          );
        },
      },
      {
        title: 'Request Sampling',
        dataIndex: 'is_request',
        key: 'is_request',
        align: 'center',
        render: (is_request, row) =>
          is_request === 0 ? (
            <Button
              onClick={() => {
                this.requestSampling(row);
              }}
              className="icon-btn"
            >
              Request
            </Button>
          ) : (
            'Already Requested'
          ),
      },
      {
        title: '',
        dataIndex: 'id',
        key: 'key',
        width: 100,
        render: (key) => (
          <Button
            style={{ width: 80 }}
            onClick={() => {
              this.showDrawer(key);
            }}
            className="icon-btn"
          >
            Detail
          </Button>
        ),
      },
      {
        title: 'Done',
        dataIndex: 'done',
        key: 'done',
        align: 'center',
        width: 100,
        render: (key, record, index) => {
          return (
            <Checkbox
              dataId={record.id}
              checked={key === 1}
              onChange={() => {
                this.onDone(record.id);
              }}
            />
          );
        },
      },
    ];
  }

  state = {
    data: [],
    pagination: { pageSize: 10 },
    loading: false,
    visible: false,
    saveButton: false,
    searchValue: '',
    searchType: '',
    searchPregnant: '',
    searchStatus: '',
    registerDateValue: '',
    range: '',
    detail: {
      id: '',
      fullname: '',
      status: '1',
      phone: '',
      is_pregnant: false,
      address_1: '',
      address_2: '',
      child_fullname: '',
      child_birthdate: '',
      danone_brands_pregnant: '',
      get_support_by: '',
      profile_status: '',
    },
  };

  requestSampling = async (row) => {
    this.props.pageLoading(true);
    var data = {};
    for (var i = 0; i < this.state.data.length; i++) {
      if (this.state.data[i].id === row.id) {
        data = this.state.data[i];
        break;
      }
    }
    console.log(data);

    var body = {
      platform: 'konchhlatclub',
      uuid: data.uuid,
      id: data.user_id,
      email: data.email,
      phone_number: data.phone,
      profile: {
        name: data.fullname,
        phone_number: data.phone,
        contact_channel: data.opt_in,
      },
      address: {
        house: data.house,
        street: data.street,
        commune: data.commune,
        sangkat: data.sangkat,
        province: data.province,
        zip_code: '',
      },
      children: [
        {
          pregnant: data.pregnant === 'Yes' ? 1 : 0,
          estimated_due_date: data.pregnant_date
            ? data.pregnant_date
            : '01/01/1970',
          gender: data.gender_pregnant === '0' ? 'Boy' : 'Girl',
          name: data.child_fullname_pregnant
            ? data.child_fullname_pregnant
            : '',
          birthdate: data.child_birthday_pregnant
            ? data.child_birthday_pregnant
            : '01/01/1970',
          delivered:
            data.labour_pregnant === 'Naturally' ? 'natural' : 'c-section',
        },
      ],
    };

    reqwest({
      url: process.env.REACT_APP_API + '/sampling/request',
      method: 'post',
      data: body,
      type: 'json',
    }).then((data) => {
      this.props.pageLoading(false);
      this.fetch();
    });
  };

  onDone = (id) => {
    var values = {
      id: id,
    };

    this.props.pageLoading(true);
    reqwest({
      url: process.env.REACT_APP_API + '/sampling/done',
      method: 'post',
      data: values,
      type: 'json',
    }).then((data) => {
      this.props.pageLoading(false);
      if (data.success) {
        this.setState({ visible: false });
        this.fetch();
      } else {
        this.setState({ saveButton: true });
      }
    });
  };

  showDrawer = async (key) => {
    var data = {};
    for (var i = 0; i < this.state.data.length; i++) {
      if (this.state.data[i].id === key) {
        data = this.state.data[i];
        break;
      }
    }

    data.mom_birthdate = data.mom_birthdate
      ? moment(data.mom_birthdate).format('DD/MM/YYYY')
      : '';
    data.child_birthdate_pregnant = data.child_birthdate_pregnant
      ? moment(data.child_birthdate_pregnant).format('DD/MM/YYYY')
      : '';
    data.child_birthdate = data.child_birthdate
      ? moment(data.child_birthdate).format('DD/MM/YYYY')
      : '';

    this.setState({
      visible: true,
      detail: data,
    });
  };

  showDeleteModal = () => {
    this.setState({
      deleteModalVisible: true,
    });
  };

  handleDeleteModalOk = () => {
    var detail = this.state.detail;
    this.setState({ deleteLoading: true });

    reqwest({
      url: process.env.REACT_APP_API + '/sampling/delete',
      method: 'post',
      data: {
        id: detail.id,
      },
      type: 'json',
    }).then(() => {
      this.setState({
        deleteLoading: false,
        deleteModalVisible: false,
        visible: false,
      });
      this.fetch();
    });
  };

  handleDeleteModalCancel = () => {
    this.setState({ deleteModalVisible: false });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  componentDidMount() {
    document.title = 'Request Sampling';

    this.fetch();
  }

  getIndexInParent = (el) => {
    return Array.from(el.parentNode.children).indexOf(el);
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.fetch({
      size: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
  };

  fetch = (params = {}) => {
    this.props.pageLoading(true);
    reqwest({
      url: process.env.REACT_APP_API + '/sampling',
      method: 'get',
      data: {
        size: this.state.pagination.pageSize,
        search: this.state.searchValue,
        type: this.state.searchType,
        pregnant: this.state.searchPregnant,
        registerDateValue: this.state.registerDateValue,
        status: this.state.searchStatus,
        range: this.state.range,
        ...params,
      },
      type: 'json',
    }).then((data) => {
      this.setState({
        data: data.results,
      });
      this.props.pageLoading(false);

      document.body.style.overflow = null;
    });
  };

  handleSearchChange(value) {
    this.setState({ searchValue: value });
    if (searchTimeoutId) {
      window.clearTimeout(searchTimeoutId);
    }
    searchTimeoutId = window.setTimeout(() => {
      this.fetch();
    }, 1500);
  }

  handleSearchType = (value) => {
    this.setState({ searchType: value });
    if (searchTimeoutId) {
      window.clearTimeout(searchTimeoutId);
    }
    searchTimeoutId = window.setTimeout(() => {
      this.fetch();
    }, 1500);
  };

  handleSearchPregnant = (value) => {
    this.setState({ searchPregnant: value });
    if (searchTimeoutId) {
      window.clearTimeout(searchTimeoutId);
    }
    searchTimeoutId = window.setTimeout(() => {
      this.fetch();
    }, 1500);
  };

  handleIsRegisterDateFilterChange = (value) => {
    if (value.length > 0) {
      var date =
        value[0].format('DD/MM/YYYY') + ',' + value[1].format('DD/MM/YYY');
      this.setState({ registerDateValue: date });
    } else {
      this.setState({ registerDateValue: '' });
    }
    if (searchTimeoutId) {
      window.clearTimeout(searchTimeoutId);
    }
    searchTimeoutId = window.setTimeout(() => {
      this.fetch();
    }, 1500);
  };

  handleRangeFilterChange = (value) => {
    this.setState({ range: value });
    if (searchTimeoutId) {
      window.clearTimeout(searchTimeoutId);
    }
    searchTimeoutId = window.setTimeout(() => {
      this.fetch();
    }, 1500);
  };

  handleSearchStatus = (value) => {
    this.setState({ searchStatus: value });
    if (searchTimeoutId) {
      window.clearTimeout(searchTimeoutId);
    }
    searchTimeoutId = window.setTimeout(() => {
      this.fetch();
    }, 1500);
  };

  handleExport() {
    var search = this.state.searchValue;
    var type = this.state.searchType;
    var pregnant = this.state.searchPregnant;
    var registerDateValue = this.state.registerDateValue;
    var status = this.state.searchStatus;
    var range = this.state.range;

    window.open(
      process.env.REACT_APP_API +
        '/sampling/export?search=' +
        search +
        '&type=' +
        type +
        '&pregnant=' +
        pregnant +
        '&status=' +
        status +
        '&registerDateValue=' +
        registerDateValue +
        '&range=' +
        range,
    );
  }

  render() {
    const { deleteModalVisible, deleteLoading, searchValue, detail } =
      this.state;
    // var showPregnantChild = "none";
    var showPregnantDate = 'none';
    var showChildInfo = 'none';
    if (detail.pregnant) {
      if (detail.pregnant.toUpperCase() === 'YES') {
        showPregnantDate = '';
      }
    }
    if (detail.have_other_children) {
      if (detail.have_other_children.toUpperCase() === 'YES') {
        showChildInfo = '';
      }
    }
    // showPregnantChild = showPregnantDate === "none" ? "" : "none";
    return (
      <div id="sampling-component">
        <Row>
          <Col span={24}>
            <Title level={1}>Request Sampling</Title>
          </Col>
        </Row>
        <Drawer
          title="Detail"
          placement="right"
          closable={false}
          width={550}
          onClose={this.onClose}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <Row>
            <Col span={12} style={{ textAlign: 'right', marginRight: '8px' }}>
              <Text>Full name:</Text>
            </Col>
            <Col span={8}>
              <Text>{detail.fullname}</Text>
            </Col>
          </Row>
          {/* <Row>
            <Col span={12} style={{ textAlign: "right", marginRight: "8px" }}>
              <Text>Birthdate:</Text>
            </Col>
            <Col span={8}>
              <Text>{detail.mom_birthdate}</Text>
            </Col>
          </Row> */}
          <Row>
            <Col span={12} style={{ textAlign: 'right', marginRight: '8px' }}>
              <Text>Phone number:</Text>
            </Col>
            <Col span={8}>
              <Text>{detail.phone}</Text>
            </Col>
          </Row>
          <Row>
            <Col span={12} style={{ textAlign: 'right', marginRight: '8px' }}>
              <Text>House N#:</Text>
            </Col>
            <Col span={8}>
              <Text>{detail.house}</Text>
            </Col>
          </Row>
          <Row>
            <Col span={12} style={{ textAlign: 'right', marginRight: '8px' }}>
              <Text>Street:</Text>
            </Col>
            <Col span={8}>
              <Text>{detail.street}</Text>
            </Col>
          </Row>

          <Row>
            <Col span={12} style={{ textAlign: 'right', marginRight: '8px' }}>
              <Text>City/Province:</Text>
            </Col>
            <Col span={8}>
              <Text>{detail.province}</Text>
            </Col>
          </Row>
          <Row>
            <Col span={12} style={{ textAlign: 'right', marginRight: '8px' }}>
              <Text>District / Khan:</Text>
            </Col>
            <Col span={8}>
              <Text>{detail.district}</Text>
            </Col>
          </Row>
          <Row>
            <Col span={12} style={{ textAlign: 'right', marginRight: '8px' }}>
              <Text>Commune / Sangkat:</Text>
            </Col>
            <Col span={8}>
              <Text>{detail.commune}</Text>
            </Col>
          </Row>
          <Row>
            <Col span={20} style={{ textAlign: 'right', marginRight: '8px' }}>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col span={12} style={{ textAlign: 'right', marginRight: '8px' }}>
              <Text>Are you pregnant?</Text>
            </Col>
            <Col span={8}>
              <Text>{detail.pregnant}</Text>
            </Col>
          </Row>
          <Row style={{ display: showPregnantDate }}>
            <Col span={12} style={{ textAlign: 'right', marginRight: '8px' }}>
              <Text>Pregnant Date:</Text>
            </Col>
            <Col span={8}>
              <Text>
                {detail.pregnant
                  ? moment(detail.pregnant_date).format('DD/MM/YYYY')
                  : ''}
              </Text>
            </Col>
          </Row>

          {/* <Row style={{display: showPregnantChild}}>
						<Col span={12} style={{textAlign:'right', marginRight: '8px'}}>
							<Text>Child Fullname:</Text>
						</Col>
						<Col span={8}>
							<Text>{detail.child_fullname_pregnant}</Text>
						</Col>
					</Row>
					<Row style={{display: showPregnantChild}}>
						<Col span={12} style={{textAlign:'right', marginRight: '8px'}}>
							<Text>Child Birthday:</Text>
						</Col>
						<Col span={8}>
							<Text>{detail.child_birthdate_pregnant}</Text>
						</Col>
					</Row>
					<Row style={{display: showPregnantChild}}>
						<Col span={12} style={{textAlign:'right', marginRight: '8px'}}>
							<Text>Gender:</Text>
						</Col>
						<Col span={8}>
							<Text>{detail.gender_pregnant}</Text>
						</Col>
					</Row>
					<Row style={{display: showPregnantChild}}>
						<Col span={12} style={{textAlign:'right', marginRight: '8px'}}>
							<Text>Labour:</Text>
						</Col>
						<Col span={8}>
							<Text>{detail.labour_pregnant}</Text>
						</Col>
					</Row>
					<Row style={{display: showPregnantChild}}>
						<Col span={12} style={{textAlign:'right', marginRight: '8px'}}>
							<Text>Child is using:</Text>
						</Col>
						<Col span={8}>
							<Text>{detail.danone_brands_pregnant}</Text>
						</Col>
					</Row> */}
          <Row>
            <Col span={20} style={{ textAlign: 'right', marginRight: '8px' }}>
              <hr />
            </Col>
          </Row>
          {/* <Row>
            <Col span={12} style={{ textAlign: "right", marginRight: "8px" }}>
              <Text>Do you have other children? :</Text>
            </Col>
            <Col span={8}>
              <Text>{detail.have_other_children}</Text>
            </Col>
          </Row> */}
          <Row style={{ display: showChildInfo }}>
            <Col span={12} style={{ textAlign: 'right', marginRight: '8px' }}>
              <Text>Child name:</Text>
            </Col>
            <Col span={8}>
              <Text>{detail.child_fullname_pregnant}</Text>
            </Col>
          </Row>
          <Row style={{ display: showChildInfo }}>
            <Col span={12} style={{ textAlign: 'right', marginRight: '8px' }}>
              <Text>Child birthdate:</Text>
            </Col>
            <Col span={8}>
              <Text>{detail.child_birthdate_pregnant}</Text>
            </Col>
          </Row>
          <Row style={{ display: showChildInfo }}>
            <Col span={12} style={{ textAlign: 'right', marginRight: '8px' }}>
              <Text>Gender:</Text>
            </Col>
            <Col span={8}>
              <Text>{detail.gender_pregnant}</Text>
            </Col>
          </Row>
          {/* <Row style={{display: showChildInfo}}>
						<Col span={12} style={{textAlign:'right', marginRight: '8px'}}>
							<Text>Labour:</Text>
						</Col>
						<Col span={8}>
							<Text>{detail.labour}</Text>
						</Col>
					</Row> */}
          {/* <Row>
            <Col span={12} style={{ textAlign: "right", marginRight: "8px" }}>
              <Text>Brand Name:</Text>
            </Col>
            <Col span={8}>
              <Text>{detail.danone_brand}</Text>
            </Col>
          </Row> */}
          <Row>
            <Col span={12} style={{ textAlign: 'right', marginRight: '8px' }}>
              <Text>Product Using:</Text>
            </Col>
            <Col span={8}>
              <Text>{detail.danone_brands_pregnant}</Text>
            </Col>
          </Row>
          <Row>
            <Col span={12} style={{ textAlign: 'right', marginRight: '8px' }}>
              <Text>Done:</Text>
            </Col>
            <Col span={8}>
              <Checkbox
                dataId={detail.id}
                checked={detail.done === 1}
                onChange={() => {
                  this.onDone(detail.id);
                }}
              />
            </Col>
          </Row>
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e8e8e8',
              padding: '10px 16px',
              left: 0,
              background: '#fff',
              borderRadius: '0 0 4px 4px',
              zIndex: 2,
            }}
          >
            <Row>
              <Col span={12}>
                <Button
                  onClick={this.showDeleteModal}
                  className="secondary-action-btn"
                >
                  Remove
                </Button>
              </Col>
              <Col span={12} className="text-right">
                <Button
                  onClick={this.onClose}
                  type="primary"
                  className="primary-action-btn"
                >
                  Close
                </Button>
              </Col>
            </Row>
          </div>
        </Drawer>

        <Modal
          visible={deleteModalVisible}
          width={400}
          closable={false}
          footer={[
            <Button
              key="submit"
              loading={deleteLoading}
              onClick={this.handleDeleteModalOk}
            >
              Yes, Delete
            </Button>,
            <Button
              key="back"
              type="primary"
              onClick={this.handleDeleteModalCancel}
              style={{ minWidth: 120 }}
            >
              No
            </Button>,
          ]}
        >
          <div class="text-center">
            <p>Do you want to delete this item?</p>
          </div>
        </Modal>

        <div className="paper-panel">
          <Row gutter={{ xs: 8, sm: 8, md: 8, lg: 8 }}>
            <Col span={5}>
              <Search
                placeholder="Full name / Phone Number "
                onChange={({ target: { value } }) =>
                  this.handleSearchChange(value)
                }
                style={{ width: '100%', marginRight: 15 }}
                value={searchValue}
              />
            </Col>
            <Col span={5}>
              <RangePicker
                format={'DD/MM/YYYY'}
                onChange={this.handleIsRegisterDateFilterChange}
              />
            </Col>
            <Col span={3}>
              <Select
                style={{ width: '100%', marginRight: 8 }}
                placeholder="Pregnant"
                onChange={this.handleSearchPregnant}
              >
                <Select.Option value="">All</Select.Option>
                <Select.Option value="Yes">Yes</Select.Option>
                <Select.Option value="No">No</Select.Option>
              </Select>
            </Col>
            <Col span={3}>
              <Select
                style={{ width: '100%', marginRight: 8 }}
                placeholder="Status"
                onChange={this.handleSearchStatus}
              >
                <Select.Option value="">All</Select.Option>
                <Select.Option value="1">Done</Select.Option>
                <Select.Option value="0">Undone</Select.Option>
              </Select>
            </Col>
            <Col span={5}>
              <Select
                style={{ width: '100%', marginRight: 8 }}
                onChange={this.handleRangeFilterChange}
                placeholder="Range"
              >
                <Select.Option value="">All</Select.Option>
                <Select.Option value="1:12">Trimester 1</Select.Option>
                <Select.Option value="13:26">Trimester 2</Select.Option>
                <Select.Option value="27:40">Trimester 3</Select.Option>
                <Select.Option value="41:44">Baby 1 Month</Select.Option>
                <Select.Option value="45:56">Baby 3 Months</Select.Option>
                <Select.Option value="57:68">Baby 6 Months</Select.Option>
                <Select.Option value="69:92">Toddler 12 Months</Select.Option>
                <Select.Option value="93:116">Toddler 18 Months</Select.Option>
                <Select.Option value="117:140">
                  Children 24 Months
                </Select.Option>
                <Select.Option value="141:188">
                  Children 36 Months
                </Select.Option>
                <Select.Option value="189:9999">
                  Children More than 36 Months
                </Select.Option>
              </Select>
            </Col>
            <Col span={3} className="text-right">
              <Button
                type="primary"
                style={{ minWidth: 100 }}
                onClick={() => this.handleExport()}
              >
                Export
              </Button>
            </Col>
          </Row>

          <Table
            rowKey="id"
            columns={this.columns}
            dataSource={this.state.data}
            pagination={this.state.pagination}
            loading={this.state.loading}
            onChange={this.handleTableChange}
          />
        </div>
      </div>
    );
  }
}

export default Form.create({ name: 'sampling' })(App);
