import React from 'react';
import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Form,
  Icon,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Table,
  Typography,
  Upload,
} from 'antd';
import 'antd/dist/antd.css';
import './article_banner.css';
import reqwest from 'reqwest';

import dragula from 'dragula';
import 'dragula/dist/dragula.css';
import moment from 'moment';

const { RangePicker } = DatePicker;
const path = require('path');
const image2base64 = require('image-to-base64');
const { Search } = Input;
const { Text, Title } = Typography;

var searchTimeoutId = false;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng =
    file.type === 'image/jpeg' ||
    file.type === 'image/png' ||
    file.type === 'image/webp';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }

  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }

  return isJpgOrPng && isLt2M;
}

class App extends React.Component {
  state = {
    data: [],
    key: false,
    loading: false,
    visible: false,
    imageUrl: false,
    imageMobileUrl: false,
    imageBase64: '',
    deleteModalVisible: false,
    deleteLoading: false,
    deleteButton: false,
    searchValue: '',
    detail: { title: ['', ''] },
  };

  constructor(props) {
    super(props);
    this.columns = [
      {
        title: 'Sort',
        dataIndex: 'sort',
        key: 'display_index',
        width: 100,
        render: () => (
          <Icon
            type="menu"
            style={{
              fontSize: '13pt',
              fontWeight: 'bolder',
              cursor: 'pointer',
            }}
          />
        ),
      },
      {
        title: '',
        dataIndex: 'image',
        key: 'image',
        width: 300,
        render: (image) => (
          <img src={image} style={{ maxHeight: 150, maxWidth: 200 }} alt="" />
        ),
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (title) => <div>{title}</div>,
      },
      {
        title: 'ref title 1',
        dataIndex: 'ref_title1',
        key: 'ref_title1',
        render: (title) => <div>{title}</div>,
      },
      {
        title: 'ref title 2',
        dataIndex: 'ref_title2',
        key: 'ref_title2',
        render: (title) => <div>{title}</div>,
      },
      {
        title: 'ref title 3',
        dataIndex: 'ref_title3',
        key: 'ref_title3',
        render: (title) => <div>{title}</div>,
      },
      {
        title: 'ref title 4',
        dataIndex: 'ref_title4',
        key: 'ref_title4',
        render: (title) => <div>{title}</div>,
      },
      {
        title: 'ref title phone',
        dataIndex: 'ref_title_phone',
        key: 'ref_title_phone',
        render: (title) => <div>{title}</div>,
      },
      {
        title: 'Active',
        dataIndex: 'status',
        key: 'status',
        width: 100,
        align: 'center',
        render: (status) =>
          status === '1' ? (
            <i className="fa fa-check" style={{ fontSize: 15 }}></i>
          ) : (
            <i className="fa fa-times" style={{ fontSize: 15 }}></i>
          ),
      },
      {
        title: '',
        dataIndex: 'key',
        key: 'key',
        width: 50,
        render: (key) => (
          <Button
            onClick={() => {
              this.showDrawer(key);
            }}
            className="icon-btn"
          >
            <Icon type="setting" />
          </Button>
        ),
      },
    ];
  }

  componentDidMount() {
    document.title = 'Article Banner';

    this.fetch();

    const container = document.querySelector('.ant-table-tbody');
    const drake = dragula([container], {
      moves: (el) => {
        this.start = this.getIndexInParent(el);
        return true;
      },
    });

    drake.on('drop', (el) => {
      this.end = this.getIndexInParent(el);
      this.handleReorder(this.start, this.end);
    });
  }

  getIndexInParent = (el) => {
    return Array.from(el.parentNode.children).indexOf(el);
  };

  showDrawer = async (key) => {
    var data = {};
    if (key === -1) {
      data = {
        url: '',
        type: '1',
        start_time: moment().format('YYYY/MM/DD'),
        end_time: moment().add('days', 7).format('YYYY/MM/DD'),
        duration: '',
        status: '1',
        image: false,
      };
      this.setState({
        imageUrl: null,
        imageMobileUrl: null,
      });
    } else {
      console.log('key -> ', key);
      console.log('start -> images list from stats -> ', this.state.data);
      data = this.state.data.find((element) => element.key === key);
      console.log('data => ', data);
      // for (var i = 0; i < this.state.data.length; i++) {
      // 	if (this.state.data[i].key === key) {
      // 		data = this.state.data[i];
      // 		break;
      // 	}
      // }
    }

    var duration = [
      moment(data.start_time, 'YYYY/MM/DD'),
      moment(data.end_time, 'YYYY/MM/DD'),
    ];
    console.log(duration);
    this.props.form.setFieldsValue({
      id: data.key,
      url: data.url,
      start_time: data.start_time,
      end_time: data.end_time,
      duration: duration,
      type: '' + data.type,
      status: data.status,
      image: false,
    });

    this.setState({
      visible: true,
      detail: data,
      deleteButton: key === -1 ? false : true,
      image: false,
    });

    image2base64(data.image) // you can also to use url
      .then((response) => {
        var b64 = 'data:image/';
        var ext = path.extname(data.image);
        if (ext === '.jpg' || ext === '.jpeg') {
          b64 += 'jpeg;base64,' + response;
        } else if (ext === '.png') {
          b64 += 'png;base64,' + response;
        } else {
          b64 = false;
        }

        this.props.form.setFieldsValue({
          image: b64,
        });

        this.setState({
          imageUrl: b64,
        });
      })
      .catch((error) => {
        console.log(error); //Exepection error....
      });

    image2base64(data.imageMobile) // you can also to use url
      .then((response) => {
        var b64 = 'data:image/';
        var ext = path.extname(data.imageMobile);
        if (ext === '.jpg' || ext === '.jpeg') {
          b64 += 'jpeg;base64,' + response;
        } else if (ext === '.png') {
          b64 += 'png;base64,' + response;
        } else {
          b64 = false;
        }
        if (b64 !== 'data:image/') {
          this.props.form.setFieldsValue({
            imageMobile: b64,
          });

          this.setState({
            imageMobileUrl: b64,
          });
        }
      })
      .catch((error) => {
        //Exepection error....
      });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  onSave = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const withHttp = (url) =>
          !/^https?:\/\//i.test(url) ? `https://${url}` : url;

        if (values.url) {
          values.url = values.url ? withHttp(values.url) : '';
        }
        if (values.duration) {
          if (values.duration.length > 0) {
            values.start_time = moment(values.duration[0]).format('YYYY/MM/DD');
          }
          if (values.duration.length > 1) {
            values.end_time = moment(values.duration[1]).format('YYYY/MM/DD');
          }
        }
        reqwest({
          url: process.env.REACT_APP_API + '/article_banner/save',
          method: 'post',
          data: values,
          type: 'json',
        }).then((data) => {
          if (data.success) {
            this.setState({ visible: false });
            message.success('Save data complete.');
            this.fetch();
          } else {
            message.error('There was a problem updating! Please try again.');
          }
        });
      }
    });
  };

  handleChange = (info) => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) => {
        this.props.form.setFieldsValue({
          image: imageUrl,
        });
        this.setState({
          imageUrl,
          loading: false,
        });
      });
    }
  };

  handleMobileChange = (info) => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageMobileUrl) => {
        console.log('imageMobileUrl -> ', imageMobileUrl);
        this.props.form.setFieldsValue({
          imageMobile: imageMobileUrl,
        });
        this.setState({
          imageMobileUrl,
          loading: false,
        });
      });
    }
  };

  handleSearchChange(value) {
    this.setState({ searchValue: value });
    if (searchTimeoutId) {
      window.clearTimeout(searchTimeoutId);
    }
    searchTimeoutId = window.setTimeout(() => {
      this.fetch();
    }, 1500);
  }

  fetch = (params = {}) => {
    console.log('params:', params);
    this.setState({ loading: true });
    reqwest({
      url: process.env.REACT_APP_API + '/article_banner',
      method: 'get',
      data: {
        search: this.state.searchValue,
        ...params,
      },
      type: 'json',
    }).then((data) => {
      this.setState({
        loading: false,
        data: data.results,
      });
      document.body.style.overflow = null;
    });
  };

  showDeleteModal = () => {
    this.setState({
      deleteModalVisible: true,
    });
  };

  handleDeleteModalCancel = () => {
    this.setState({ deleteModalVisible: false });
  };

  handleDeleteModalOk = () => {
    var detail = this.state.detail;
    this.setState({ deleteLoading: true });

    reqwest({
      url: process.env.REACT_APP_API + '/article_banner/delete',
      method: 'post',
      data: {
        id: detail.key,
      },
      type: 'json',
    }).then(() => {
      this.setState({
        deleteLoading: false,
        deleteModalVisible: false,
        visible: false,
      });
      message.success('Remove data complete.');
      this.fetch();
    });
  };

  handleReorder = (dragIndex, draggedIndex) => {
    const data = [...this.state.data];
    const item = data.splice(dragIndex, 1)[0];
    data.splice(draggedIndex, 0, item);
    this.setState({
      data,
    });

    var postBody = [];
    for (var i = 0; i < data.length; i++) {
      postBody.push({
        key: data[i].key,
        display_order: i + 1,
      });
    }

    console.log(postBody);

    reqwest({
      url: process.env.REACT_APP_API + '/article_banner/reorder',
      method: 'post',
      data: {
        v: postBody,
      },
      type: 'json',
    }).then(() => {});
  };

  render() {
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    const {
      deleteModalVisible,
      deleteLoading,
      deleteButton,
      imageMobileUrl,
      imageUrl,
      detail,
    } = this.state;
    const { getFieldDecorator } = this.props.form;

    return (
      <div id="article-banner-component">
        <Drawer
          id="article-banner-component-drawer"
          title="EDIT"
          placement="right"
          closable={false}
          width={730}
          onClose={this.onClose}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <Form onSubmit={this.onSave} layout={'vertical'}>
            <Form.Item label="" style={{ display: 'none' }}>
              {getFieldDecorator('id', {
                initialValue: '',
              })(<Input />)}
            </Form.Item>

            <Form.Item label="Name">
              {getFieldDecorator('name', {
                initialValue: detail.name,
              })(<Input placeholder="Name" />)}
            </Form.Item>

            <Form.Item label="Desktop Banner">
              <Upload
                accept=".png, .jpg"
                name="Upload"
                listType="picture-card"
                className="photo-uploader"
                showUploadList={false}
                action={process.env.REACT_APP_API + '/image/test/upload'}
                beforeUpload={beforeUpload}
                onChange={this.handleChange}
              >
                {imageUrl ? (
                  <img src={imageUrl} alt="Upload" style={{ width: '100%' }} />
                ) : (
                  uploadButton
                )}
              </Upload>
              {getFieldDecorator('image')(<Input type="hidden" />)}
              <div className="text-center">
                <Text>1900 x 800</Text>
              </div>
            </Form.Item>
            <Form.Item label="Mobile Banner">
              <Upload
                accept=".png, .jpg, .webp"
                name="Upload"
                listType="picture-card"
                className="photo-uploader"
                showUploadList={false}
                action={process.env.REACT_APP_API + '/image/test/upload'}
                beforeUpload={beforeUpload}
                onChange={this.handleMobileChange}
              >
                {imageMobileUrl ? (
                  <img
                    src={imageMobileUrl}
                    alt="Upload"
                    style={{ width: '100%' }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
              {getFieldDecorator('imageMobile')(<Input type="hidden" />)}
              <div className="text-center">
                <Text>800 x 600</Text>
              </div>
            </Form.Item>

            <Form.Item label="Title ref1 (Optional)">
              {getFieldDecorator('ref_title1', {
                initialValue: detail.ref_title1,
              })(<Input placeholder="Title 1" />)}
            </Form.Item>
            <Form.Item label="url ref1 (Optional)">
              {getFieldDecorator('ref_url1', {
                initialValue: detail.ref_url1,
              })(<Input placeholder="https://" />)}
            </Form.Item>

            <Form.Item label="Title ref2 (Optional)">
              {getFieldDecorator('ref_title2', {
                initialValue: detail.ref_title2,
              })(<Input placeholder="Title 2" />)}
            </Form.Item>
            <Form.Item label="url ref2 (Optional)">
              {getFieldDecorator('ref_url2', {
                initialValue: detail.ref_url2,
              })(<Input placeholder="https://" />)}
            </Form.Item>

            <Form.Item label="Title ref3 (Optional)">
              {getFieldDecorator('ref_title3', {
                initialValue: detail.ref_title3,
              })(<Input placeholder="Title 3" />)}
            </Form.Item>
            <Form.Item label="url ref3 (Optional)">
              {getFieldDecorator('ref_url3', {
                initialValue: detail.ref_url3,
              })(<Input placeholder="https://" />)}
            </Form.Item>

            <Form.Item label="Title ref4 (Optional)">
              {getFieldDecorator('ref_title4', {
                initialValue: detail.ref_title4,
              })(<Input placeholder="Title 4" />)}
            </Form.Item>
            <Form.Item label="url ref4 (Optional)">
              {getFieldDecorator('ref_url4', {
                initialValue: detail.ref_url4,
              })(<Input placeholder="https://" />)}
            </Form.Item>

            <Form.Item label="Title ref Phone (Optional)">
              {getFieldDecorator('ref_title_phone', {
                initialValue: detail.ref_title_phone,
              })(<Input placeholder="Title Phone" />)}
            </Form.Item>
            <Form.Item label="url ref5 (Optional)">
              {getFieldDecorator('ref_phone', {
                initialValue: detail.ref_phone,
              })(<Input placeholder="https://" />)}
            </Form.Item>

            <Form.Item label="Publish Status*">
              {getFieldDecorator('status', {
                validateTrigger: ['onChange', 'onBlur'],
                initialValue: '1',
                rules: [
                  { required: true, message: 'Please input your status!' },
                ],
              })(
                <Radio.Group>
                  <Radio.Button value="1">
                    <i className="fa fa-check" style={{ marginRight: 5 }}></i>
                    Active
                  </Radio.Button>
                  <Radio.Button value="0">
                    <i className="fa fa-close" style={{ marginRight: 5 }}></i>
                    Inactive
                  </Radio.Button>
                </Radio.Group>,
              )}
            </Form.Item>

            <div
              style={{
                position: 'absolute',
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e8e8e8',
                padding: '10px 16px',
                left: 0,
                background: '#fff',
                borderRadius: '0 0 4px 4px',
                zIndex: 2,
              }}
            >
              <Row>
                <Col span={12}>
                  <Button
                    onClick={this.onClose}
                    style={{ background: '#EDEDED' }}
                  >
                    Close
                  </Button>
                </Col>
                <Col span={12} className="text-right">
                  <Button
                    style={{
                      marginRight: 8,
                    }}
                    disabled={deleteButton ? false : true}
                    onClick={this.showDeleteModal}
                  >
                    <i className="fa fa-close" style={{ marginRight: 5 }}></i>
                    Delete
                  </Button>
                  <Button htmlType="submit" type="primary">
                    Save
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </Drawer>
        <Row>
          <Col span={24}>
            <Title level={1}>Article Banner</Title>
          </Col>
        </Row>
        <div className="paper-panel">
          <Row>
            <Col span={12}>
              <Search
                placeholder="Title"
                onChange={({ target: { value } }) =>
                  this.handleSearchChange(value)
                }
                style={{ width: 350 }}
              />
            </Col>
            <Col span={12} className="text-right">
              <Button
                type="primary"
                style={{ minWidth: 140 }}
                onClick={() => this.showDrawer(-1)}
              >
                <Icon type="plus" />
                Create
              </Button>
            </Col>
          </Row>
          <Table
            columns={this.columns}
            dataSource={this.state.data}
            pagination={this.state.pagination}
            loading={this.state.loading}
            onChange={this.handleTableChange}
          />
        </div>
        <Modal
          visible={deleteModalVisible}
          closable={false}
          width={400}
          footer={[
            <Button
              key="submit"
              loading={deleteLoading}
              onClick={this.handleDeleteModalOk}
            >
              Yes, Delete
            </Button>,
            <Button
              key="back"
              type="primary"
              onClick={this.handleDeleteModalCancel}
              style={{ minWidth: 120 }}
            >
              No
            </Button>,
          ]}
        >
          <div className="text-center">
            <p>Do you want to delete this item?</p>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Form.create({ name: 'article_banner_form' })(App);
