import React from 'react';
import {
  Button,
  Checkbox,
  Col,
  Drawer,
  Form,
  Icon,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Spin,
  Table,
  Tabs,
  Typography,
  Upload,
} from 'antd';
import 'antd/dist/antd.css';
import './home_banner.css';
import reqwest from 'reqwest';
import Dotdotdot from 'react-dotdotdot';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import dragula from 'dragula';
import 'dragula/dist/dragula.css';
import moment from 'moment';
import { getProductURL } from './util/var';
import { editorConfig } from './config.js';
import { wrapEditorTable } from './util/editor.util';
import TextEditor from './components/TextEditor';
import { LoadingOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;
const path = require('path');
const image2base64 = require('image-to-base64');
const { Search } = Input;
const { Text, Title } = Typography;

var searchTimeoutId = false;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }

  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }

  return isJpgOrPng && isLt2M;
}

function validateImage(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }

  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }

  return isJpgOrPng && isLt2M;
}

function uploadImage(file, cb) {
  let reader = new FileReader();
  reader.readAsDataURL(file);

  reader.onload = function () {
    var b64 = reader.result;
    reqwest({
      url: process.env.REACT_APP_API + '/image/save',
      method: 'post',
      data: {
        image: b64,
      },
      type: 'json',
    }).then((data) => {
      cb(data.src);
    });
  };

  reader.onerror = function (error) {
    console.log('Error: ', error);
  };

  return false;
}

var Size = ReactQuill.Quill.import('attributors/style/size');
Size.whitelist = ['13px', '14px', '16px', '18px', '20px', '32px', '72px'];
ReactQuill.Quill.register(Size, true);

class App extends React.Component {
  state = {
    data: [],
    key: false,
    id: '',
    visible: false,
    imageUrl: false,
    imageBase64: '',
    saveButton: false,
    deleteModalVisible: false,
    deleteLoading: false,
    deleteButton: false,
    searchValue: '',
    detail: {
      title: ['', ''],
      information: '',
      information_kh: '',
      preparation: '',
      preparation_kh: '',
      nutrition_info: '',
      nutrition_info_kh: '',
      url_name: '',
    },
    loading: false,
  };

  constructor(props) {
    super(props);
    this.columns = [
      {
        title: 'Product Name',
        dataIndex: 'name',
        key: 'name',
        render: (data) => (
          <div>
            <Dotdotdot clamp={1}>
              <Text>{data}</Text>
            </Dotdotdot>
          </div>
        ),
      },
      {
        title: 'Created Date',
        dataIndex: 'created_time',
        key: 'created_time',
        width: 200,
        render: (data) => (
          <div>
            <Text style={{ wordWrap: 'break-word' }}>
              {moment(data).format('DD/MM/YYYY')}
            </Text>
          </div>
        ),
      },
      {
        title: 'Published',
        dataIndex: 'status',
        key: 'published',
        width: 100,
        align: 'center',
        render: (status) =>
          '' + status === '1' ? (
            <i className="fa fa-check" style={{ fontSize: 15 }}></i>
          ) : (
            <i className="fa fa-times" style={{ fontSize: 15 }}></i>
          ),
      },
      {
        title: '',
        dataIndex: 'id',
        key: 'id',
        width: 50,
        render: (key) => (
          <Button
            onClick={() => {
              this.showDrawer(key);
            }}
            className="icon-btn"
          >
            <Icon type="setting" />
          </Button>
        ),
      },
    ];
  }

  componentDidMount() {
    document.title = 'Product';

    this.fetch();

    const container = document.querySelector('.ant-table-tbody');
    const drake = dragula([container], {
      moves: (el) => {
        this.start = this.getIndexInParent(el);
        return true;
      },
    });

    drake.on('drop', (el) => {
      this.end = this.getIndexInParent(el);
      this.handleReorder(this.start, this.end);
    });
  }

  getIndexInParent = (el) => {
    return Array.from(el.parentNode.children).indexOf(el);
  };

  showDrawer = async (key) => {
    var data = {};

    if (key === -1) {
      data = {
        id: '',
        name: '',
        sub_title: '',
        img: '',
        desc: '',
        information_use: 0,
        information: '',
        information_kh: '',
        preparation_use: 0,
        preparation: '',
        preparation_kh: '',
        nutrition_use: 0,
        nutrition_info: '',
        nutrition_info_kh: '',
        type: '',
        status: 1,
        url_name: '',
        created_time: '',
        updated_time: '',
      };
      this.setState({
        id: '',
        imageUrl: null,
      });
    } else {
      for (var i = 0; i < this.state.data.length; i++) {
        if (this.state.data[i].id === key) {
          data = this.state.data[i];
          break;
        }
      }
    }

    data.status = '' + data.status;
    data.type = '' + data.type;

    this.props.form.setFieldsValue(data);

    this.setState({
      visible: true,
      id: key === -1 ? '' : key,
      detail: data,
      saveButton: true,
      deleteButton: key === -1 ? false : true,
    });

    if (data.img) {
      image2base64(data.img) // you can also to use url
        .then((response) => {
          var b64 = 'data:image/';
          var ext = path.extname(data.img);
          if (ext === '.jpg' || ext === '.jpeg') {
            b64 += 'jpeg;base64,' + response;
          } else if (ext === '.png') {
            b64 += 'png;base64,' + response;
          }

          this.props.form.setFieldsValue({
            image: b64,
          });

          this.setState({
            imageUrl: b64,
          });
        })
        .catch((error) => {
          console.log(error); //Exepection error....
        });
    }
  };

  onClose = () => {
    this.setState({
      visible: false,
      id: '',
      detail: {
        ...this.state.detail,
        information: undefined,
        information_kh: undefined,
      },
    });
  };

  onSave = (e) => {
    this.setState({ loading: true });
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ saveButton: false });
        const { id } = this.state;

        values.information_use = values.information_use ? 1 : 0;
        values.preparation_use = values.preparation_use ? 1 : 0;
        values.nutrition_use = values.nutrition_use ? 1 : 0;
        // console.log({values});
        // set value from editor
        values.information = wrapEditorTable(this.state.detail.information);
        values.information_kh = wrapEditorTable(
          this.state.detail.information_kh,
        );
        values.preparation = wrapEditorTable(this.state.detail.preparation);
        values.preparation_kh = wrapEditorTable(
          this.state.detail.preparation_kh,
        );
        values.nutrition_info = wrapEditorTable(
          this.state.detail.nutrition_info,
        );
        values.nutrition_info_kh = wrapEditorTable(
          this.state.detail.nutrition_info_kh,
        );
        reqwest({
          url: process.env.REACT_APP_API + '/product/save',
          method: 'post',
          data: { ...values, id },
          type: 'json',
        })
          .then(async (data) => {
            if (data.success) {
              this.setState({ loading: false });
              this.setState({ visible: false });
              const article = await this.fetchArticle();
              this.setState({
                data: article,
              });
              message.success('Save data complete.');

              // if(id !== "" && id !== undefined && id !== null){
              // 	this.showDrawer(id)
              // }else{
              // 	this.showDrawer(data.message.row_last)
              // }
            } else {
              this.setState({ loading: false });
              this.setState({ visible: true });
              message.error('There was a problem updating! Please try again.');
            }
          })
          .catch((error) => {
            console.log('Error' + error);
            this.setState({ visible: true });
            this.setState({ loading: false });
          });
      }
    });
  };

  handleChange = (info) => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) => {
        this.props.form.setFieldsValue({
          image: imageUrl,
        });
        this.setState({
          imageUrl,
          loading: false,
        });
      });
    }
  };

  handleSearchChange(value) {
    this.setState({ searchValue: value });
    if (searchTimeoutId) {
      window.clearTimeout(searchTimeoutId);
    }
    searchTimeoutId = window.setTimeout(() => {
      this.fetch();
    }, 1500);
  }

  fetchArticle = async (params = {}) => {
    return await reqwest({
      url: process.env.REACT_APP_API + '/product',
      method: 'get',
      data: {
        search: this.state.searchValue,
        ...params,
      },
      type: 'json',
    }).then((data) => {
      return data.results;
    });
  };

  fetch = (params = {}) => {
    console.log('params:', params);
    this.setState({ loading: true });
    reqwest({
      url: process.env.REACT_APP_API + '/product',
      method: 'get',
      data: {
        search: this.state.searchValue,
        ...params,
      },
      type: 'json',
    }).then((data) => {
      this.setState({
        loading: false,
        data: data.results,
      });
      document.body.style.overflow = null;
    });
  };

  showDeleteModal = () => {
    this.setState({
      deleteModalVisible: true,
    });
  };

  handleDeleteModalCancel = () => {
    this.setState({ deleteModalVisible: false });
  };

  handleDeleteModalOk = () => {
    var detail = this.state.detail;
    this.setState({ deleteLoading: true });

    reqwest({
      url: process.env.REACT_APP_API + '/product/delete',
      method: 'post',
      data: {
        id: detail.id,
      },
      type: 'json',
    }).then(() => {
      this.setState({
        deleteLoading: false,
        deleteModalVisible: false,
        visible: false,
      });
      message.success('Remove data complete.');
      this.fetch();
    });
  };

  handleReorder = (dragIndex, draggedIndex) => {
    const data = [...this.state.data];
    const item = data.splice(dragIndex, 1)[0];
    data.splice(draggedIndex, 0, item);
    this.setState({
      data,
    });

    var postBody = [];
    for (var i = 0; i < data.length; i++) {
      postBody.push({
        key: data[i].key,
        display_order: i + 1,
      });
    }

    console.log(postBody);

    reqwest({
      url: process.env.REACT_APP_API + '/product/reorder',
      method: 'post',
      data: {
        v: postBody,
      },
      type: 'json',
    }).then(() => {});
  };

  modules = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        ['link', 'image', 'video'],

        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        // [{ 'direction': 'rtl' }],                         // text direction

        // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ size: Size.whitelist }],

        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        // [{ 'font': Font.whitelist }],
        [{ align: [] }],

        ['clean'], // remove formatting button
      ],
      handlers: {
        image: this.imageHandler,
      },
    },
  };

  imageHandler() {
    const input = document.createElement('input');

    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      if (!validateImage(file)) {
        return false;
      }
      const formData = new FormData();

      formData.append('image', file);

      // Save current cursor state
      const range = this.quill.getSelection(true);

      // Insert temporary loading placeholder image
      // this.quill.insertEmbed(range.index, 'image', `${window.location.origin}/images/loaders/placeholder.gif`);

      // Move cursor to right side of image (easier to continue typing)
      this.quill.setSelection(range.index + 1);

      uploadImage(file, (cb) => {
        this.quill.insertEmbed(range.index, 'image', cb);
      });

      // Remove placeholder image
      // this.quill.deleteText(range.index, 1);

      // Insert uploaded image
      // this.quill.insertEmbed(range.index, 'image', res.body.image);
      // this.quill.insertEmbed(range.index, 'image', res);
    };
  }

  getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  };

  handleChangeEditor = (key, value) => {
    this.setState({
      detail: {
        ...this.state.detail,
        [key]: value,
      },
    });
  };

  getPreviewURL = () => {
    const condition = {
      'localhost:3051': 'https://konchhlatclub.com.kh',
      '159.89.192.236:3051': 'https://dumex-cambodia-dev.pirsquare.net',
      '188.166.246.19:3041': 'https://dumex-cambodia-uat.pirsquare.net',
      'admin.konchhlatclub.dumex.rgb72.dev':
        'https://konchhlatclub.dumex.rgb72.dev/km',
    };
    return condition[window.location.host] ?? 'https://konchhlatclub.com.kh';
  };

  render() {
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    const {
      saveButton,
      deleteButton,
      deleteModalVisible,
      deleteLoading,
      imageUrl,
      searchValue,
      detail,
      modalVisible,
      modalVisibleContent,
    } = this.state;
    const { getFieldDecorator } = this.props.form;

    return (
      <div id="home-banner-component">
        <Spin tip="Loading..." spinning={this.state.loading} delay={100}>
          <Drawer
            id="home-banner-component-drawer"
            title="PRODUCT DETAIL"
            placement="right"
            closable={false}
            width={730}
            destroyOnClose
            onClose={this.onClose}
            visible={this.state.visible}
            bodyStyle={{ paddingBottom: 80 }}
          >
            <Form onSubmit={this.onSave} layout={'vertical'}>
              <Form.Item label="" style={{ display: 'none' }}>
                {getFieldDecorator('id', {
                  initialValue: '',
                })(<Input />)}
              </Form.Item>
              <Form.Item label="Product Name KH*">
                {getFieldDecorator('name_kh', {
                  initialValue: detail.name_kh,
                  rules: [
                    { required: true, message: 'Please input Product Name!' },
                  ],
                })(<Input placeholder="" maxLength={1000} />)}
              </Form.Item>
              <Form.Item label="Product Name EN*">
                {getFieldDecorator('name', {
                  initialValue: detail.name,
                  rules: [
                    { required: true, message: 'Please input Product Name!' },
                  ],
                })(<Input placeholder="" maxLength={1000} />)}
              </Form.Item>
              <Form.Item label="Product Subtitle KH*">
                {getFieldDecorator('sub_title_kh', {
                  initialValue: detail.sub_title_kh,
                  rules: [
                    {
                      required: true,
                      message: 'Please input Product Subtitle!',
                    },
                  ],
                })(<Input placeholder="" maxLength={1000} />)}
              </Form.Item>
              <Form.Item label="Product Subtitle EN*">
                {getFieldDecorator('sub_title', {
                  initialValue: detail.sub_title,
                  rules: [
                    {
                      required: true,
                      message: 'Please input Product Subtitle!',
                    },
                  ],
                })(<Input placeholder="" maxLength={1000} />)}
              </Form.Item>
              <Form.Item label="Product Description KH*">
                {getFieldDecorator('desc_kh', {
                  initialValue: detail.desc_kh,
                  rules: [
                    {
                      required: true,
                      message: 'Please input Product Description!',
                    },
                  ],
                })(<Input placeholder="" maxLength={1000} />)}
              </Form.Item>
              <Form.Item label="Product Description EN*">
                {getFieldDecorator('desc', {
                  initialValue: detail.desc,
                  rules: [
                    {
                      required: true,
                      message: 'Please input Product Description!',
                    },
                  ],
                })(<Input placeholder="" maxLength={1000} />)}
              </Form.Item>
              <Form.Item label="Product Category*">
                {getFieldDecorator('type', {
                  initialValue: detail.type,
                  rules: [
                    {
                      required: true,
                      message: 'Please input Product Description!',
                    },
                  ],
                })(
                  <Select
                    style={{ width: '100%', marginRight: 8 }}
                    placeholder="Please select"
                  >
                    <Select.Option value="1">Dugro</Select.Option>
                    <Select.Option value="2">Supergold</Select.Option>
                  </Select>,
                )}
              </Form.Item>
              <Form.Item>
                <div>Product Image</div>
                <Upload
                  accept=".png, .jpg"
                  name="Upload"
                  listType="picture-card"
                  showUploadList={false}
                  action={process.env.REACT_APP_API + '/image/test/upload'}
                  beforeUpload={beforeUpload}
                  onChange={this.handleChange}
                >
                  {imageUrl ? (
                    <img
                      src={imageUrl}
                      alt="Upload"
                      style={{ width: '100%' }}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>

                {getFieldDecorator('image')(<Input type="hidden" />)}
                <div>
                  <Text>300 x 200</Text>
                </div>
              </Form.Item>
              <Tabs defaultActiveKey="1">
                <TabPane tab="Product Information" key="1">
                  {getFieldDecorator('information_use', {
                    initialValue: detail.information_use,
                    valuePropName: 'checked',
                  })(
                    <Checkbox>
                      <strong>Use this section</strong>
                    </Checkbox>,
                  )}
                  <span style={{ marginTop: '10px', display: 'block' }}>
                    <strong>Description EN</strong>
                  </span>
                  {/* {getFieldDecorator('information', {
											initialValue: detail.information,
										})(
											<ReactQuill id="product-information" modules={this.modules} />
										)} */}
                  <TextEditor
                    value={this.state.detail.information}
                    ref={this.setRef}
                    config={editorConfig}
                    onChange={(e) => this.handleChangeEditor('information', e)}
                  />
                  <span style={{ marginTop: '10px', display: 'block' }}>
                    <strong>Description KH</strong>
                  </span>
                  {/* {getFieldDecorator('information_kh', {
											initialValue: detail.information_kh,
										})(
											<ReactQuill id="product-information-kh" modules={this.modules} />
										)} */}
                  <TextEditor
                    value={this.state.detail.information_kh}
                    ref={this.setRef}
                    config={editorConfig}
                    onChange={(e) =>
                      this.handleChangeEditor('information_kh', e)
                    }
                  />
                </TabPane>
                <TabPane tab="Preparation" key="2">
                  {getFieldDecorator('preparation_use', {
                    initialValue: detail.preparation_use,
                    valuePropName: 'checked',
                  })(
                    <Checkbox>
                      <strong>Use this section</strong>
                    </Checkbox>,
                  )}

                  <span style={{ marginTop: '10px', display: 'block' }}>
                    <strong>Description EN</strong>
                  </span>
                  {/* {getFieldDecorator('preparation', {
											initialValue: detail.preparation,
										})(
											<ReactQuill id="product-preparation" modules={this.modules} />
										)} */}
                  <TextEditor
                    value={this.state.detail.preparation}
                    ref={this.setRef}
                    config={editorConfig}
                    onChange={(e) => this.handleChangeEditor('preparation', e)}
                  />

                  <span style={{ marginTop: '10px', display: 'block' }}>
                    <strong>Description KH</strong>
                  </span>
                  {/* {getFieldDecorator('preparation_kh', {
											initialValue: detail.preparation_kh,
										})(
											<ReactQuill id="product-preparation-kh" modules={this.modules} />
										)} */}
                  <TextEditor
                    value={this.state.detail.preparation_kh}
                    ref={this.setRef}
                    config={editorConfig}
                    onChange={(e) =>
                      this.handleChangeEditor('preparation_kh', e)
                    }
                  />
                </TabPane>
                <TabPane tab="Nutrition Information" key="3">
                  {getFieldDecorator('nutrition_use', {
                    initialValue: detail.nutrition_use,
                    valuePropName: 'checked',
                  })(
                    <Checkbox>
                      <strong>Use this section</strong>
                    </Checkbox>,
                  )}

                  <span style={{ marginTop: '10px', display: 'block' }}>
                    <strong>Description EN</strong>
                  </span>
                  {/* {getFieldDecorator('nutrition_info', {
											initialValue: detail.nutrition_info,
										})(
											<ReactQuill id="product-nutrition_info" modules={this.modules}  />
										)} */}
                  <TextEditor
                    value={this.state.detail.nutrition_info}
                    ref={this.setRef}
                    config={editorConfig}
                    onChange={(e) =>
                      this.handleChangeEditor('nutrition_info', e)
                    }
                  />

                  <span style={{ marginTop: '10px', display: 'block' }}>
                    <strong>Description KH</strong>
                  </span>
                  {/* {getFieldDecorator('nutrition_info_kh', {
											initialValue: detail.nutrition_info_kh,
										})(
											<ReactQuill id="product-nutrition_info-kh" modules={this.modules} />
										)} */}
                  <TextEditor
                    value={this.state.detail.nutrition_info_kh}
                    ref={this.setRef}
                    config={editorConfig}
                    onChange={(e) =>
                      this.handleChangeEditor('nutrition_info_kh', e)
                    }
                  />
                </TabPane>
              </Tabs>
              <br />
              <Form.Item label="URL Slug">
                {getFieldDecorator('url_name', {
                  initialValue: detail.url_name,
                  rules: [{ required: false, message: 'Please input url!' }],
                })(<Input placeholder="" maxLength={255} />)}
              </Form.Item>
              <Form.Item label="Preview URL">
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={`${this.getPreviewURL()}${getProductURL(detail)}`}
                >{`${this.getPreviewURL()}${getProductURL(detail)}`}</a>
              </Form.Item>

              <Form.Item label="Link to URL (Optional)">
                {getFieldDecorator('link_url', {
                  initialValue: detail.link_url,
                })(<Input placeholder="https://" />)}
              </Form.Item>

              <Form.Item label="Publish Status*">
                {getFieldDecorator('status', {
                  validateTrigger: ['onChange', 'onBlur'],
                  initialValue: '1',
                  rules: [
                    { required: true, message: 'Please input your status!' },
                  ],
                })(
                  <Radio.Group>
                    <Radio.Button value="1">
                      <i className="fa fa-check" style={{ marginRight: 5 }}></i>
                      Active
                    </Radio.Button>
                    <Radio.Button value="0">
                      <i className="fa fa-close" style={{ marginRight: 5 }}></i>
                      Inactive
                    </Radio.Button>
                  </Radio.Group>,
                )}
              </Form.Item>

              <div
                style={{
                  position: 'absolute',
                  bottom: 0,
                  width: '100%',
                  borderTop: '1px solid #e8e8e8',
                  padding: '10px 16px',
                  left: 0,
                  background: '#fff',
                  borderRadius: '0 0 4px 4px',
                  zIndex: 2,
                }}
              >
                <Row>
                  <Col span={12}>
                    <Button
                      onClick={this.onClose}
                      style={{ background: '#EDEDED' }}
                    >
                      Close
                    </Button>
                  </Col>
                  <Col span={12} className="text-right">
                    <Button
                      style={{
                        marginRight: 8,
                      }}
                      disabled={deleteButton ? false : true}
                      onClick={this.showDeleteModal}
                    >
                      <i className="fa fa-close" style={{ marginRight: 5 }}></i>
                      Delete
                    </Button>
                    <Button
                      disabled={saveButton ? false : true}
                      htmlType="submit"
                      type="primary"
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          </Drawer>
        </Spin>
        <Row>
          <Col span={24}>
            <Title level={1}>Products</Title>
          </Col>
        </Row>
        <div className="paper-panel">
          <Row>
            <Col span={12}>
              <Search
                placeholder="Product Name"
                onChange={({ target: { value } }) =>
                  this.handleSearchChange(value)
                }
                value={searchValue}
                style={{ width: 350 }}
              />
            </Col>
            <Col span={12} className="text-right">
              <Button
                type="primary"
                onClick={() => this.showDrawer(-1)}
                style={{ minWidth: 140 }}
              >
                <Icon type="plus" />
                Create
              </Button>
            </Col>
          </Row>
          <Table
            columns={this.columns}
            dataSource={this.state.data.map((row, index) => {
              return Object.assign(row, { key: index });
            })}
            loading={this.state.loading}
          />
        </div>
        <Modal
          visible={deleteModalVisible}
          closable={false}
          width={400}
          footer={[
            <Button
              key="submit"
              loading={deleteLoading}
              onClick={this.handleDeleteModalOk}
            >
              Yes, Delete
            </Button>,
            <Button
              key="back"
              type="primary"
              onClick={this.handleDeleteModalCancel}
              style={{ minWidth: 120 }}
            >
              No
            </Button>,
          ]}
        >
          <div className="text-center">
            <p>Do you want to delete this item?</p>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Form.create({ name: 'product' })(App);
