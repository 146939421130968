import React from 'react';
import { Drawer, Icon, Row, Select, Table, Typography } from 'antd';
import Search from 'antd/lib/input/Search';
import reqwest from 'reqwest';

import { DndProvider, DragSource, DropTarget } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';

const { Text } = Typography;
let searchTimeoutId = false;

let dragingIndex = -1;

class BodyRow extends React.Component {
  render() {
    const {
      isOver,
      connectDragSource,
      connectDropTarget,
      moveRow,
      ...restProps
    } = this.props;
    const style = { ...restProps.style, cursor: 'move' };

    let { className } = restProps;
    if (isOver) {
      if (restProps.index > dragingIndex) {
        className += ' drop-over-downward';
      }
      if (restProps.index < dragingIndex) {
        className += ' drop-over-upward';
      }
    }

    return connectDragSource(
      connectDropTarget(
        <tr {...restProps} className={className} style={style} />,
      ),
    );
  }
}

const rowSource = {
  beginDrag(props) {
    dragingIndex = props.index;
    return {
      index: props.index,
    };
  },
};

const rowTarget = {
  drop(props, monitor) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Time to actually perform the action
    props.moveRow(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  },
};

const DragableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
}))(
  DragSource('row', rowSource, (connect) => ({
    connectDragSource: connect.dragSource(),
  }))(BodyRow),
);

class ArticleHomeCategory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      searchValue: null,
      loading: false,
      category: [],
      searchCategory: '',
    };

    this.columns = this.columns = [
      {
        title: 'Sort',
        dataIndex: 'sorting',
        key: 'sorting',
        width: 100,
        render: () => (
          <Icon
            type="menu"
            className="draggable"
            style={{
              fontSize: '13pt',
              fontWeight: 'bolder',
              cursor: 'pointer',
            }}
          />
        ),
      },
      {
        title: '',
        dataIndex: 'thumbnail_photo',
        key: 'image',
        render: (image) => <img width="50" src={image} alt="Thumbnail" />,
      },
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        render: (data) => (
          <div>
            <Text>{data[0]}</Text>
            <br />
            <Text>{data[1]}</Text>
          </div>
        ),
      },
      {
        title: 'Category',
        dataIndex: 'category_name',
        key: 'category',
        render: (data) => <Text>{data[1]}</Text>,
      },
      {
        title: 'Group',
        dataIndex: 'article_group_name',
        key: 'group',
        render: (data) => <Text>{data[1]}</Text>,
      },
      {
        title: 'Topic',
        dataIndex: 'topic_name',
        key: 'topic',
        render: (data) => <Text>{data[1]}</Text>,
      },
    ];
  }

  componentDidMount() {
    this.fetch();
  }

  moveRow = (dragIndex, hoverIndex) => {
    const { data } = this.state;
    const dragRow = data[dragIndex];

    this.setState(
      update(this.state, {
        data: {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        },
      }),
    );

    this.handleReorder(dragIndex, hoverIndex);
  };

  handleReorder = (dragIndex, draggedIndex) => {
    const category = this.state.category.find(
      (item) => item.id === this.state.searchCategory,
    );
    const sortingBy = category && category.name_en.toLowerCase();
    const data = this.state.data.map((item, index) => {
      return {
        key: item.key,
        [`${sortingBy ? sortingBy : ''}${!!sortingBy ? '_' : ''}sorting`]:
          index + 1,
      };
    });
    reqwest({
      url: process.env.REACT_APP_API + '/article/home/reorder',
      method: 'post',
      data: {
        data,
        type: sortingBy || 'default',
      },
      type: 'json',
    });
  };

  handleSetArticleHomeCategory = (openArticleHomeCategoryDrawer) => {
    const { handleSetArticleHomeCategory } = this.props;
    handleSetArticleHomeCategory(openArticleHomeCategoryDrawer);
  };

  handleSearchChange(value) {
    this.setState({ searchValue: value });
    if (searchTimeoutId) {
      window.clearTimeout(searchTimeoutId);
    }
    searchTimeoutId = window.setTimeout(() => {
      this.fetch();
    }, 1500);
  }

  onClose = () => {
    this.handleSetArticleHomeCategory(false);
  };

  fetch = (params = {}) => {
    this.props.pageLoading(true);
    const category = this.state.category.find(
      (item) => item.id === this.state.searchCategory,
    );
    const sortingBy = category && category.name_en.toLowerCase();
    let data = {
      category: this.state.searchCategory,
      sortingBy: sortingBy ? `${sortingBy}_sorting` : undefined,
    };
    reqwest({
      url: process.env.REACT_APP_API + '/article/home/selected',
      method: 'get',
      type: 'json',
      data,
    }).then((selected) => {
      reqwest({
        url: process.env.REACT_APP_API + '/article/home',
        method: 'get',
        data: {
          search: this.state.searchValue,
          category: this.state.searchCategory,
          topic: this.state.searchTopic,
          group: this.state.searchGroup,
          ...params,
        },
        type: 'json',
      }).then((data) => {
        this.setState({ data: [] });
        if (category && sortingBy) {
          const selectedData = selected.results.map((item) => {
            return {
              ...item,
              sorting: item[`${sortingBy}_sorting`],
            };
          });
          const articleData = data.results.map((item) => {
            return {
              ...item,
              sorting: item[`${sortingBy}_sorting`],
            };
          });
          let array = [...selectedData];
          articleData.forEach((item) => {
            const exist = array.find((f) => f.key === item.key);
            if (!exist) {
              array.push(item);
            }
          });
          this.setState({ data: array });
        }
        // else {
        //     let array = [...selected.results]
        //     data.results.forEach((item) => {
        //         const exist = array.find(f => f.key === item.key)
        //         if (!exist) {
        //             array.push(item)
        //         }
        //     })
        //     this.setState({ data: array });
        // }
        this.props.pageLoading(false);
      });
    });
    reqwest({
      url: process.env.REACT_APP_API + '/article/init',
      method: 'get',
      data: {},
      type: 'json',
    }).then((data) => {
      this.setState({
        category: data.results.category,
      });
    });
  };

  components = {
    body: {
      row: DragableBodyRow,
    },
  };

  handleCategoryChange = (value) => {
    if (searchTimeoutId) {
      window.clearTimeout(searchTimeoutId);
    }
    this.setState({
      searchCategory: value,
    });
    searchTimeoutId = window.setTimeout(() => {
      this.fetch();
    }, 500);
  };

  render() {
    const { openArticleHomeCategoryDrawer } = this.props;
    const { searchCategory, category } = this.state;
    return (
      <>
        <Drawer
          title="CATEGORY PAGE HIGHLIGHT"
          placement="right"
          closable={false}
          width={930}
          onClose={this.onClose}
          visible={openArticleHomeCategoryDrawer}
          bodyStyle={{ paddingBottom: 120 }}
        >
          <Row type="flex" justify="space-between">
            <Select
              defaultValue=""
              value={searchCategory}
              style={{ width: 220, marginRight: 8 }}
              onChange={this.handleCategoryChange}
            >
              <Select.Option value="" disabled>
                Please select category
              </Select.Option>
              {category.map((value) => (
                <Select.Option key={value.id} value={value.id}>
                  {value.name_en}
                </Select.Option>
              ))}
            </Select>
            <Search
              placeholder="Title"
              onChange={({ target: { value } }) =>
                this.handleSearchChange(value)
              }
              style={{ width: 240, marginRight: 8 }}
            />
          </Row>
          <DndProvider backend={HTML5Backend}>
            <Table
              columns={this.columns}
              dataSource={this.state.searchCategory ? this.state.data : []}
              loading={this.state.loading}
              components={this.components}
              onRow={(record, index) => ({
                index,
                moveRow: this.moveRow,
              })}
              rowClassName={(record, index) => {
                return index < 3 ? 'highlight' : '';
              }}
              pagination={false}
            />
          </DndProvider>
        </Drawer>
      </>
    );
  }
}

export default ArticleHomeCategory;
