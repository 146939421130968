import React from 'react';
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Spin,
  Tabs,
  Typography,
} from 'antd';
import 'antd/dist/antd.css';
import './promotion.css';
import reqwest from 'reqwest';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { editorConfig } from './config.js';
import { wrapEditorTable } from './util/editor.util';
import TextEditor from './components/TextEditor';

const { Text, Title } = Typography;

function validateImage(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }

  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }

  return isJpgOrPng && isLt2M;
}

function uploadImage(file, cb) {
  let reader = new FileReader();
  reader.readAsDataURL(file);

  reader.onload = function () {
    var b64 = reader.result;
    reqwest({
      url: process.env.REACT_APP_API + '/image/save',
      method: 'post',
      data: {
        image: b64,
      },
      type: 'json',
    }).then((data) => {
      cb(data.src);
    });
  };

  reader.onerror = function (error) {
    console.log('Error: ', error);
  };

  return false;
}

var Size = ReactQuill.Quill.import('attributors/style/size');
Size.whitelist = ['13px', '14px', '16px', '18px', '20px', '32px', '72px'];
ReactQuill.Quill.register(Size, true);

class App extends React.Component {
  state = {
    loading: false,
  };

  componentDidMount() {
    document.title = 'Promotion';

    this.fetch();
  }

  modules = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        ['link', 'image'],

        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        // [{ 'direction': 'rtl' }],                         // text direction

        // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ size: Size.whitelist }],

        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        // [{ 'font': Font.whitelist }],
        [{ align: [] }],

        ['clean'], // remove formatting button
      ],
      handlers: {
        image: this.imageHandler,
      },
    },
  };

  imageHandler() {
    const input = document.createElement('input');

    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      if (!validateImage(file)) {
        return false;
      }
      const formData = new FormData();

      formData.append('image', file);

      // Save current cursor state
      const range = this.quill.getSelection(true);

      // Insert temporary loading placeholder image
      // this.quill.insertEmbed(range.index, 'image', `${window.location.origin}/images/loaders/placeholder.gif`);

      // Move cursor to right side of image (easier to continue typing)
      this.quill.setSelection(range.index + 1);

      uploadImage(file, (cb) => {
        this.quill.insertEmbed(range.index, 'image', cb);
      });

      // Remove placeholder image
      // this.quill.deleteText(range.index, 1);

      // Insert uploaded image
      // this.quill.insertEmbed(range.index, 'image', res.body.image);
      // this.quill.insertEmbed(range.index, 'image', res);
    };
  }

  getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  };

  fetch = (params = {}) => {
    this.setState({ loading: true });
    reqwest({
      url: process.env.REACT_APP_API + '/promotion',
      method: 'get',
      data: {
        ...params,
      },
      type: 'json',
    }).then((data) => {
      this.setState({
        detail: {
          title_en: data.results.title_en,
          title_kh: data.results.title_kh,
          description_en: data.results.description_en,
          description_kh: data.results.description_kh,
          merchant_description_en: data.results.merchant_description_en,
          merchant_description_kh: data.results.merchant_description_kh,
        },
      });
      this.props.form.setFieldsValue({
        title_en: data.results.title_en,
        title_kh: data.results.title_kh,
        description_en: data.results.description_en,
        description_kh: data.results.description_kh,
        merchant_description_en: data.results.merchant_description_en,
        merchant_description_kh: data.results.merchant_description_kh,
      });
      document.body.style.overflow = null;
      this.setState({
        loading: false,
      });
      this.props.pageLoading(false);
    });
  };

  onSave = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      // console.log("values -> ",values);
      this.setState({ loading: true });
      if (!err) {
        this.props.pageLoading(true);
        // set value from editor
        values.description_en = wrapEditorTable(
          this.state.detail.description_en,
        );
        values.description_kh = wrapEditorTable(
          this.state.detail.description_kh,
        );
        values.merchant_description_en = wrapEditorTable(
          this.state.detail.merchant_description_en,
        );
        values.merchant_description_kh = wrapEditorTable(
          this.state.detail.merchant_description_kh,
        );
        reqwest({
          url: process.env.REACT_APP_API + '/promotion/save',
          method: 'post',
          data: values,
          type: 'json',
        }).then((data) => {
          this.fetch();
          if (data.success) {
            this.setState({ loading: false });
            this.setState({ visible: false });
            message.success('Save data complete.');
            this.fetch();
          } else {
            this.setState({ saveButton: true });
            message.error('There was a problem updating! Please try again.');
            this.fetch();
          }
        });
      }
    });
  };

  handleChangeEditor = (key, value) => {
    this.setState({
      detail: {
        ...this.state.detail,
        [key]: value,
      },
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    if (!this.state.detail) {
      return <></>;
    }

    return (
      <Form onSubmit={this.onSave} layout={'vertical'}>
        <Spin tip="Loading..." spinning={this.state.loading} delay={100}>
          <div id="promotion-component">
            <Row>
              <Col span={12}>
                <Title level={1}>Promotion</Title>
              </Col>
              <Col span={12} className="text-right">
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ minWidth: 140, marginTop: 13 }}
                >
                  Save
                </Button>
              </Col>
            </Row>
            <div className="paper-panel">
              <Row>
                <Col xl={18}>
                  <Form.Item label="Title EN*">
                    {getFieldDecorator('title_en', {
                      initialValue: '',
                      rules: [
                        { required: true, message: 'Please input your title!' },
                      ],
                    })(<Input placeholder="input placeholder" />)}
                  </Form.Item>
                  <Form.Item label="Title KH*">
                    {getFieldDecorator('title_kh', {
                      initialValue: '',
                      rules: [
                        { required: true, message: 'Please input your title!' },
                      ],
                    })(<Input placeholder="input placeholder" />)}
                  </Form.Item>
                  <Form.Item label="Description *">
                    {/* {getFieldDecorator('description_en', {
                        initialValue: detail.description,
                        rules: [{ required: true, message: 'Please input your description!' }],
                      })(
                        <ReactQuill id="promotion-desc-en" modules={this.modules} height={300} />
                      )} */}
                    <TextEditor
                      value={this.state.detail.description_en}
                      ref={this.setRef}
                      config={editorConfig}
                      onChange={(e) =>
                        this.handleChangeEditor('description_en', e)
                      }
                    />
                  </Form.Item>

                  <Form.Item label="Description KH *">
                    {/* {getFieldDecorator('description_kh', {
                        initialValue: '',
                        rules: [{ required: true, message: 'Please input your description!' }],
                      })(
                        <ReactQuill id="promotion-desc-kh" modules={this.modules} height={300} />
                      )} */}
                    <TextEditor
                      value={this.state.detail.description_kh}
                      ref={this.setRef}
                      config={editorConfig}
                      onChange={(e) =>
                        this.handleChangeEditor('description_kh', e)
                      }
                    />
                  </Form.Item>

                  <Text strong={true}>PARTICIPATING MERCHANTS</Text>
                  <hr className="divider" />

                  <Form.Item label="Description EN *">
                    {/* {getFieldDecorator('merchant_description_en', {
                        initialValue: '',
                        rules: [{ required: true, message: 'Please input your description!' }],
                      })(
                        <ReactQuill id="merchant-desc-en" modules={this.modules} height={300} />
                      )} */}
                    <TextEditor
                      value={this.state.detail.merchant_description_en}
                      ref={this.setRef}
                      config={editorConfig}
                      onChange={(e) =>
                        this.handleChangeEditor('merchant_description_en', e)
                      }
                    />
                  </Form.Item>

                  <Form.Item label="Description KH *">
                    {/* {getFieldDecorator('merchant_description_kh', {
                        initialValue: '',
                        rules: [{ required: true, message: 'Please input your description!' }],
                      })(
                        <ReactQuill id="merchant-desc-kh" modules={this.modules} height={300} />
                      )} */}
                    <TextEditor
                      value={this.state.detail.merchant_description_kh}
                      ref={this.setRef}
                      config={editorConfig}
                      onChange={(e) =>
                        this.handleChangeEditor('merchant_description_kh', e)
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </div>
        </Spin>
      </Form>
    );
  }
}

export default Form.create({ name: 'promotion_form' })(App);
