export const editorConfig = {
  buttons: [
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'fontsize',
    'paragraph',
    'brush',
    '|',
    'center',
    'left',
    'right',
    'justify',
    '|',
    'ul',
    'ol',
    '\n',
    'undo',
    'redo',
    '|',
    'image',
    'video',
    'table',
    '|',
    'link',
    'preview',
    'fullsize',
  ],
  showXPathInStatusbar: false,
  showCharsCounter: false,
  showWordsCounter: false,
  toolbarAdaptive: true,
  enableDragAndDropFileToEditor: true,
  disablePlugins: ['mobile'],
  link: {
    formTemplate: function (e) {
      return '<form class="form_url"><input ref="url_input" type="url" placeholder="https://" value="https://"></input><button>save</button></form>';
    },
  },
  uploader: {
    url: process.env.REACT_APP_API + '/image/save',
    format: 'json',
    pathVariableName: 'path',
    insertImageAsBase64URI: false,
    imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
    filesVariableName: function () {
      return 'image';
    },
    queryBuild: function (data) {
      return JSON.stringify(data);
    },
    contentType: function () {
      return 'application/json';
    },
    buildData: function (data) {
      return new Promise(function (resolve, reject) {
        var reader = new FileReader();
        reader.readAsDataURL(data.getAll('image')[0]);
        reader.onload = function () {
          return resolve({
            image: reader.result,
          });
        };
        reader.onerror = function (error) {
          reject(error);
        };
      });
    },
    process: function (resp) {
      return {
        files: resp['src'] || [],
        path: resp.path,
        baseurl: resp.baseurl,
        error: resp.error,
        msg: resp.msg,
      };
    },
    isSuccess: function (resp) {
      return !resp.error;
    },
    getMsg: function (resp) {
      return resp.msg.join !== undefined ? resp.msg.join(' ') : resp.msg;
    },
    defaultHandlerSuccess: function (data, resp) {
      let field = 'files';
      this.selection.insertImage(data[field]);
    },
  },
  style: {
    backgroundColor: '#FFFFFF',
  },
  width: '100vw',
};
